import {useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {getImageDetails} from "../api/ImagesAPI";
import BlogAssetFormModal from "../components/blog/BlogAssetFormModal";
import {createFile} from "../api/FilesAPI";
import {Center, Flex, HStack, Image, Tooltip, useDisclosure} from "@chakra-ui/react";
import {AddIcon} from "@chakra-ui/icons";

export default function FamilyTree() {
    const {id} = useParams();
    const [isLoading, setLoading] = React.useState(true);
    const [image, setImage] = React.useState<any>({});
    const [refreshFamilyTree, setRefreshFamilyTree] = React.useState(true);
    const createImageModal = useDisclosure();
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);

    const handleSuccessfulFamilyTreeCreation = () => {
        setRefreshFamilyTree(true);
    }

    useEffect(()=>{
        if (refreshFamilyTree) {
            getImageDetails(id)
                .then(res => {
                    setImage(res);
                    setLoading(false);
                    setRefreshFamilyTree(false);
                })
                .catch(err => console.log(err))
        }
    },[id])

    return (
        <>
            <BlogAssetFormModal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={createImageModal.isOpen}
                onClose={createImageModal.onClose}
                asset={undefined}
                assetType={'file'}
                postConnectId={undefined}
                createAssetFunction={createFile}
                handleSuccessfulAssetCreation={handleSuccessfulFamilyTreeCreation}
            />
            <Center>
                <Image />
                <HStack
                    spacing={2}
                    mb={'5'}
                    pt={10}>
                    <Flex
                        mx="auto"
                        align="center"
                        justify="center"
                        px={5}
                        minW={'75px'}
                        minH={'125px'}
                        borderColor={'brand.green.900'}
                        borderWidth={2}
                        borderRadius={5}
                        cursor={'pointer'}
                        onClick={createImageModal.onOpen}>
                        <Center>
                            <Tooltip bgColor={'brand.green.500'} color={'brand.green.50'} label={'Add File'}>
                                <AddIcon />
                            </Tooltip>
                        </Center>
                    </Flex>
                </HStack>
            </Center>
        </>
    )
}