import { GraphQLClient, gql } from 'graphql-request';
import {createAsset, deleteAsset} from "./AssetsAPI";

export async function getFiles() {
    const graphcms = new GraphQLClient(process.env.REACT_APP_GRAPHCMS_ENDPOINT!!, {
        headers: {
            "Authorization": `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN!!}`
        }
    });

    const query = gql`
    {
        files {
            file {
              handle
              fileName
              id
              url(transformation: {document: {output: {format: jpg}}})
              fullUrl: url
            }
            id
            description
            title
            posts {
              id
            }
            tags {
              id
              name
            }
          }
    }`;

    const {files} = await graphcms.request(query);
    return files;
}

export async function deleteFile(fileId: string, fileAssetId: string) {
    const url = process.env.REACT_APP_GRAPHCMS_ENDPOINT!!
    const graphcms = new GraphQLClient(url, {
        headers: { "Authorization" : `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN}`}
    })
    await graphcms.request(
        `
        mutation($id: ID!) {
          deleteFile(where: { id: $id }){
            id
          }
        }
        `,
        { id: fileId }
    );

    await deleteAsset(fileAssetId);
}

export interface CreateFileItem {
    title: string,
    description: string,
    file: any,
    postConnectId?: string
}

export async function updateFile(fileId: string, file: CreateFileItem, asset: any): Promise<string> {
    const title = file.title;
    const description = file.description;
    const url = process.env.REACT_APP_GRAPHCMS_ENDPOINT!!
    const graphcms = new GraphQLClient(url, {
        headers: { "Authorization" : `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN}`}
    })

    console.log(file)
    let fileAssetId = '';
    let fileAssetConnect = '';
    let fileAssetVar = '';
    if (file.postConnectId) {
        if (file.file.length === 0) {
            fileAssetConnect = ', file: {delete: true}';
        } else if (file.file[0].filename !== asset.file.handle) {
            fileAssetId = await createAsset(file.file[0]);
            fileAssetConnect = `, posts: {connect: {id: $filePostId}}`;
            fileAssetVar = `, $filePostId: ID`;
        } else {
            //TODO: Delete old asset?
        }
    }

    let filePostConnect = ``;
    let filePostVar = ``;
    if (file.postConnectId) {
        filePostConnect = `, posts: {connect: {id: $filePostId}}`;
        filePostVar = `, $filePostId: ID`;
    }

    let {updateFile} = await graphcms.request(
        `
        mutation($title: String!, $description: String!, $fileAssetId: ID!${filePostVar}) {
          updateFile(
            data: { title: $title, description: $description, file: {connect: {id: $fileAssetId}}${filePostConnect} }
            where: { id: $id }
          ) {
            id
          }
        }
        `,
        { id: fileId, title: title, description: description, fileAssetId: fileAssetId, filePostId: file.postConnectId }
    )

    let {publishFile} = await graphcms.request(
        `
        mutation publishFile($id: ID!) {
            publishFile(where: {id: $id}) {
                id
            }
        }`,
        { id: updateFile.id }
    )

    return publishFile;
}

export async function createFile(file: CreateFileItem): Promise<string> {
    const title = file.title;
    const description = file.description;
    const url = process.env.REACT_APP_GRAPHCMS_ENDPOINT!!
    const graphcms = new GraphQLClient(url, {
        headers: { "Authorization" : `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN}`}
    })

    let fileAssetId = await createAsset(file.file[0]);

    let filePostConnect = ``;
    let filePostVar = ``;
    if (file.postConnectId) {
        filePostConnect = `, posts: {connect: {id: $filePostId}}`;
        filePostVar = `, $filePostId: ID`;
    }

    let {createFile} = await graphcms.request(
        `
        mutation($title: String!, $description: String!, $fileAssetId: ID!${filePostVar}) {
          createFile(data: {title: $title, description: $description, file: {connect: {id: $fileAssetId}}${filePostConnect}}) {
            id
          }
        }
        `,
        { title: title, description: description, fileAssetId: fileAssetId, filePostId: file.postConnectId }
    )

    let {publishFile} = await graphcms.request(
        `
        mutation publishFile($id: ID!) {
            publishFile(where: {id: $id}) {
                id
            }
        }`,
        { id: createFile.id }
    )

    return publishFile;
}