import { useState } from 'react';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import Editor from 'ckeditor5-custom-build';
import { useField } from 'formik';
import { FormControl, FormLabel } from '@chakra-ui/react';

export default function BlogContentEditor({
    content,
    required,
    fullWidth,
    placeholder,
    textContainerStyle,
    defaultValue,
    ...props
  }: any) {
    const [field, meta, helpers] = useField<{}>(props);
    const { setValue } = helpers;
    const contentValue = (content !== undefined && content !== null) ? content : defaultValue
    const [editorValue, setEditorValue] = useState<any[]>(contentValue);

    return (
        <FormControl>
            <FormLabel color={'brand.green.900'} fontFamily={'sans-serif'}>Content of Post</FormLabel>
            <CKEditor
                    color={'brand.green.900'}
                    editor={ Editor }
                    data={editorValue}
                    config={{
                        forcePasteAsPlainText: false,
                        pasteFromWordRemoveFontStyles: false,
                        pasteFromWordRemoveStyles: false,
                        allowedContent: true,
                        extraAllowedContent: 'p(mso*,Normal)',
                        pasteFilter: null
                    }}
                    onReady={ (editor: any) => {
                        // console.log(editor);
                    } }

                    onChange={ ( event: any, editor: any ) => {
                        const data = editor.getData();
                        setValue(data);
                        setEditorValue(data);
                    } }
                    onBlur={ ( event: any, editor: any ) => {
                        
                    } }
                    onFocus={ ( event: any, editor: any ) => {
                        
                    } }
                />
        </FormControl>
    )
}