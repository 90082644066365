import { jsx } from 'slate-hyperscript'

export const deserialize = (el: any, markAttributes = {}): any => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent)
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    console.log("invalid Node: " + el.nodeType)
    return null
  }

  const nodeAttributes: any = { ...markAttributes }

  const children = Array.from(el.childNodes)
    .map(node => deserialize(node, nodeAttributes))
    .flat()

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''))
  }

  let res: any = children;

  switch (el.nodeName) {
    case 'BODY':
      res = jsx('fragment', {}, children);
      break;
    case 'BR':
      res = '\n';
      break;
    case 'A':
      res = jsx(
        'element',
        { type: 'a', url: el.getAttribute('href') },
        children
      );
      break;
    default:
      res = jsx('element', { type: el.nodeName.toLowerCase() }, children);
      break;
  }

  if (el.attributes && el.attributes.src) {
    res.data = {
      ...res.data,
      src: el.attributes.src.value
    }
  }

  if (el.attributes && el.attributes.class) {
    res.data = {
      ...res.data,
      class: el.attributes.class.value
    }
  }

  if (el.attributes && el.attributes.style) {
    const styles = el.attributes.style.value.split(';').reduce((acc: any, style: any) => {
      const [key, value] = style.split(':');
      if (key && value) {
        acc[key.trim()] = value.trim();
      }
      return acc;
    }, {});
    res.data = {
      ...res.data,
      style: styles,
    };
  }

  return res;
}

export function astToHtml(ast: any): any {
  let html = '';
  try {
    if (ast) {
      if (ast.children) {
        for (const node of ast.children) {
          if (node.text) {
            html += node.text
          } else {
            html += `<${node.type}`;
            if (node.data && node.data.style) {
              html += ` style="${Object.entries(node.data.style).map(([key, value]) => `${key}: ${value};`).join(' ')}"`;
            }
            if (node.data && node.data.src) {
              html += ` src=${node.data.src}`;
            }
            if (node.data && node.data.class) {
              html += ` class=${node.data.class}`;
            }
            html += `>${astToHtml(node)}</${node.type}>`;
          }
        }
      } else {
        if (ast.text) {
          html += ast.text;
        }
      }
    }
  } catch (err: any) {
    console.log(err);
    console.log(ast);
  }
  return html;
}