import { GraphQLClient, gql } from 'graphql-request';
import {createAsset, deleteAsset} from "./AssetsAPI";

export async function getImageDetails(title: string | undefined): Promise<any> {
    if (title === undefined) {
        throw new Error("Oops, there was an issue here.  No image title defined")
    }

    const graphcms = new GraphQLClient(process.env.REACT_APP_GRAPHCMS_ENDPOINT!!, {
        headers: {
            "Authorization": `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN!!}`
        }
    });
    const query = gql`
        query Image($title: String!) {
          image(where: {title: $title}) {
            id
            img {
              handle
              fileName
              id
              url
            }
          }
        }
      `;

    const {image} = await graphcms.request(query, {title: title});
    return image;
}

export async function getImages() {
    const graphcms = new GraphQLClient(process.env.REACT_APP_GRAPHCMS_ENDPOINT!!, {
        headers: {
            "Authorization": `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN!!}`
        }
    });

    const query = gql`
    {
        images {
            img {
              handle
              fileName
              id
              url
            }
            id,
            posts {
              id
            }
          }
    }`;

    const {images} = await graphcms.request(query);
    return images;
}

export async function deleteImage(imageId: string, imageAssetId: string) {
    const url = process.env.REACT_APP_GRAPHCMS_ENDPOINT!!
    const graphcms = new GraphQLClient(url, {
        headers: { "Authorization" : `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN}`}
    })
    await graphcms.request(
        `
        mutation($id: ID!) {
          deleteImage(where: { id: $id }){
            id
          }
        }
        `,
        { id: imageId }
    );

    await deleteAsset(imageAssetId);
}

export interface CreateImageItem {
    title: string,
    image: any,
    postConnectId?: string
}

export async function updateImage(image: CreateImageItem): Promise<string> {
    //TODO: do update
    return "";
}

export async function createImage(image: CreateImageItem): Promise<string> {
    const title = image.title;
    const url = process.env.REACT_APP_GRAPHCMS_ENDPOINT!!
    const graphcms = new GraphQLClient(url, {
        headers: { "Authorization" : `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN}`}
    })

    let imageAssetId = await createAsset(image.image[0]);

    let imagePostConnect = ``;
    let imagePostVar = ``;
    if (image.postConnectId) {
        imagePostConnect = `, posts: {connect: {id: $filePostId}}`;
        imagePostVar = `, $filePostId: ID`;
    }

    let {createImage} = await graphcms.request(
        `
        mutation($title: String!, $imageAssetId: ID!${imagePostVar}) {
          createImage(data: {title: $title, file: {connect: {id: $imageAssetId}}${imagePostConnect}}) {
            id
          }
        }
        `,
        { title: title, imageAssetId: imageAssetId, filePostId: image.postConnectId }
    )

    let {publishImage} = await graphcms.request(
        `
        mutation publishImage($id: ID!) {
            publishImage(where: {id: $id}) {
                id
            }
        }`,
        { id: createImage.id }
    )

    return publishImage;
}