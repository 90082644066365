import { CheckCircleIcon, InfoIcon, WarningIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { Flex, Heading, HStack, Text, Toast } from '@chakra-ui/react';

function getStatusProps(status: string) {
    switch(status) {
      case 'info':
        return {
            toastColor: 'brand.green.50',
            toastBGColor: 'brand.blue.500',
            toastBorderColor: 'brand.blue.900',
            icon: <InfoIcon color={'brand.green.50'} />
        };
      case 'error':
        return {
            toastColor: 'brand.green.50',
            toastBGColor: 'brand.red.500',
            toastBorderColor: 'brand.red.900',
            icon: <WarningTwoIcon color={'brand.green.50'} />
        };
      case 'success':
        return {
            toastColor: 'brand.green.50',
            toastBGColor: 'brand.teal.500',
            toastBorderColor: 'brand.teal.900',
            icon: <CheckCircleIcon color={'brand.green.50'} />
        };
      case 'warn':
        return {
            toastColor: 'brand.green.500',
            toastBGColor: 'brand.yellow.500',
            toastBorderColor: 'brand.yellow.900',
            icon: <WarningIcon color={'brand.green.500'} />
        };
      default:
        return {
            toastColor: 'brand.green.500',
            toastBGColor: 'brand.green.500',
            toastBorderColor: 'brand.green.900',
            icon: <></>
        }
    }
}

export default function CustomToast(props: any){
  const { title, details, status, ...style } = props;

  const statusProps = getStatusProps(status);

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      {...style}
      py={4}
      px={4}
      borderLeft="3px solid"
      borderRight="3px solid"
      borderRadius={'15px'}
      borderColor={statusProps.toastBorderColor}
      bgColor={statusProps.toastBGColor}
    >
        <HStack>
            {statusProps.icon}
            <Heading
                as="h4"
                fontSize="md"
                fontWeight="500"
                color={statusProps.toastColor}
            >
                {props.title}
            </Heading>
            {props.details ? (
                <Text color={statusProps.toastColor}>{props.details}</Text>
            ) : null}
        </HStack>
    </Flex>
  );
};