import React from "react";
import {Flex, Image, Tooltip, useDisclosure} from "@chakra-ui/react";
import BlogFileModal from "./BlogFileModal";

export default function BlogFile({fileRef, handleFileDelete}: any) {
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);
    const blogFileModal = useDisclosure();

    function getFileTitle(fileRef: any): string {
        return fileRef?.title && fileRef?.title.trim() !== '' ? fileRef?.title : fileRef?.file.fileName;
    }

    return (
        <>
            <BlogFileModal
                isOpen={blogFileModal.isOpen}
                onClose={blogFileModal.onClose}
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                fileRef={fileRef}
                handleFileDelete={handleFileDelete}
            />
            <Flex
                maxW={'75px'}
                maxH={'125px'}
                minW={'75px'}
                minH={'125px'}
                width={'auto'}
                height={'auto'}
                mx="auto"
                align="center"
                justify="center"
                borderColor={'brand.green.900'}
                borderWidth={2}
                borderRadius={5}
                overflow={'hidden'}
                onClick={blogFileModal.onOpen}
            >
                <Tooltip bgColor={'brand.green.500'} color={'brand.green.50'} label={getFileTitle(fileRef)}>
                    <Image src={fileRef?.file?.url} alt={getFileTitle(fileRef)} />
                </Tooltip>
            </Flex>
        </>
    )
}