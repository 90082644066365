import {
    Box,
    Button,
    Card,
    CardBody,
    Center,
    Container,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    HStack,
    Input,
    Text,
    Textarea,
    Flex,
    VStack,
    useEditableControls,
    ButtonGroup,
    IconButton,
    Editable,
    EditablePreview,
    EditableInput,
    Spinner,
    EditableTextarea, useMediaQuery
} from "@chakra-ui/react";
import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {getSiteConfig, upsertSiteConfig} from "../api/SiteConfigAPI";
import {AutoResizeTextarea} from "../components/AutoResizeTextarea";
import {Field, Formik} from 'formik';
import * as yup from "yup";
import {CheckIcon, CloseIcon, DeleteIcon, EditIcon} from "@chakra-ui/icons";

export default function ContactUs() {
    const { isAuthenticated, user } = useAuth0();
    const [loading, setLoading] = useState(true);
    const [heading, setHeading] = useState<any>({});
    const [description, setDescription] = useState<any>({});
    const [isLessThan480px] = useMediaQuery('(max-width: 480px)');

    const validationSchema = yup.object({
        firstName: yup.string().required("First name required").matches(/^[A-Za-z\s\W_]*[A-Za-z]$/, 'First name can only contain letters.'),
        lastName: yup.string().required("Last name is required").matches(/^[A-Za-z\s\W_]*[A-Za-z]$/, 'Last name can only contain letters.'),
        email: yup.string().required("Email is required").email("Must be a valid email address"),
        message: yup.string().required("Message is required")
    });

    const updateSiteConfig = (config: any, setValue: Function) => {
        upsertSiteConfig(config).then(res => {
            setValue(res);
            setLoading(false);
        })
            .catch(err => {
                console.log(err);
                setLoading(false);
            })
    }

    useLayoutEffect(() => {
        if (loading) {
            getSiteConfig("contactUsHeading").then(res=>{
                setHeading(res)
                getSiteConfig("contactUsDescription").then(res => {
                    setDescription(res);
                    setLoading(false);
                })
                    .catch(err=> {
                        console.log(err);
                        setLoading(false);
                    })
            })
                .catch(err=>{
                    console.log(err);
                    setLoading(false);
                })
        }
    },[loading])

    const updateHeading = (value: string) => {
        let updatedHeading = heading;
        updatedHeading.value = value;
        setHeading(updatedHeading);
    }

    const updateDescription = (value: string) => {
        let updatedDescription = description;
        updatedDescription.value = value;
        setDescription(updatedDescription);
    }

    function EditableControls() {
        const {
            isEditing,
            getSubmitButtonProps,
            getCancelButtonProps,
            getEditButtonProps,
        } = useEditableControls();

        return isAuthenticated ? (
            isEditing ? (
                <ButtonGroup justifyContent={'center'} size={'sm'}>
                    <IconButton aria-label={'Cancel Button'} icon={<CloseIcon />} {...getCancelButtonProps()} variant={'unstyled'} color={'brand.red.900'}/>
                    <IconButton aria-label={'Submit Button'} icon={<CheckIcon />} {...getSubmitButtonProps()} variant={'unstyled'} />
                </ButtonGroup>
            ) : (
                <Flex justifyContent={'center'}>
                    <IconButton aria-label={'Edit Button'} icon={<EditIcon />} {...getEditButtonProps()} variant={'unstyled'} />
                </Flex>
            )
        ) : <></>
    }

    return (
        <Center mt={10}>
            {loading ?
                <Spinner />
                :
                <VStack>
                    <Text
                        fontSize={isLessThan480px ? 'medium' : 'large'}
                        color={'brand.gold.100'}
                    >Got a question?</Text>
                    <Editable
                        textAlign={'center'}
                        fontSize={isLessThan480px ? '2xl' : '4xl'}
                        isPreviewFocusable={false}
                        onChange={updateHeading}
                        defaultValue={heading.value}
                        onSubmit={() => updateSiteConfig(heading, updateHeading)}
                    >
                        <HStack>
                            <EditablePreview fontWeight={'bold'}/>
                            <Input as={EditableInput} />
                            <EditableControls />
                        </HStack>
                    </Editable>
                    <Editable
                        textAlign={'center'}
                        fontSize={isLessThan480px ? 'medium' : 'large'}
                        maxW={'70%'}
                        isPreviewFocusable={false}
                        onChange={updateDescription}
                        defaultValue={description.value}
                        pb={10}
                        onSubmit={() => updateSiteConfig(description, updateDescription)}
                    >
                        <HStack>
                            <EditablePreview/>
                            <Input as={EditableTextarea} />
                            <EditableControls />
                        </HStack>
                    </Editable>
                    <Card maxW={'sm'} variant={'elevated'}>
                        <CardBody>
                            <Formik
                                initialValues={{firstName: "", lastName: "", email: "", message: ""}}
                                onSubmit={(values, actions) => {
                                    setTimeout(() => {
                                        //submit contact info
                                        console.log(values);
                                    }, 1000)
                                }}
                                validationSchema={validationSchema}
                            >
                                {({
                                      handleSubmit,
                                      handleChange,
                                      handleBlur,
                                      errors,
                                      touched,
                                      dirty,
                                      isValid
                                  }) => (
                                    <form onSubmit={handleSubmit}>
                                        <VStack align={'stretch'}>
                                            <HStack alignItems={'center'}>
                                                <Box>
                                                    <Field name='firstName'>
                                                        {({field, form}: any) => (
                                                            <FormControl
                                                                isInvalid={!!errors.firstName && !!touched.firstName}
                                                                minH={'100px'}>
                                                                <FormLabel
                                                                    color={!!errors.firstName && !!touched.firstName ? 'brand.red.900' : 'brand.green.900'}
                                                                    fontFamily={'sans-serif'}
                                                                    textTransform={"uppercase"}
                                                                    fontWeight={"bold"}
                                                                >
                                                                    First Name
                                                                </FormLabel>
                                                                <Input
                                                                    color={'brand.green.900'}
                                                                    focusBorderColor={!!errors.firstName && !!touched.firstName ? 'brand.red.900' : 'brand.green.900'}
                                                                    errorBorderColor={'brand.red.900'}
                                                                    name='firstName'
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    defaultValue={''}
                                                                />
                                                                <FormErrorMessage
                                                                    color={'brand.red.900'}>{errors?.firstName?.toString()}</FormErrorMessage>
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                </Box>
                                                <Box>
                                                    <Field name='lastName'>
                                                        {({field, form}: any) => (
                                                            <FormControl
                                                                isInvalid={!!errors.lastName && !!touched.lastName}
                                                                minH={'100px'}>
                                                                <FormLabel
                                                                    color={!!errors.lastName && !!touched.lastName ? 'brand.red.900' : 'brand.green.900'}
                                                                    fontFamily={'sans-serif'}
                                                                    textTransform={"uppercase"}
                                                                    fontWeight={"bold"}
                                                                >
                                                                    Last Name
                                                                </FormLabel>
                                                                <Input
                                                                    color={'brand.green.900'}
                                                                    focusBorderColor={!!errors.lastName && !!touched.lastName ? 'brand.red.900' : 'brand.green.900'}
                                                                    errorBorderColor={'brand.red.900'}
                                                                    name='lastName'
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    defaultValue={''}
                                                                />
                                                                <FormErrorMessage
                                                                    color={'brand.red.900'}>{errors?.lastName?.toString()}</FormErrorMessage>
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                </Box>
                                            </HStack>
                                            <Box>
                                                <Field name='email'>
                                                    {({field, form}: any) => (
                                                        <FormControl isInvalid={!!errors.email && !!touched.email}
                                                                     minH={'100px'}>
                                                            <FormLabel
                                                                color={!!errors.email && !!touched.email ? 'brand.red.900' : 'brand.green.900'}
                                                                fontFamily={'sans-serif'}
                                                                textTransform={"uppercase"}
                                                                fontWeight={"bold"}
                                                            >
                                                                Email
                                                            </FormLabel>
                                                            <Input
                                                                color={'brand.green.900'}
                                                                focusBorderColor={!!errors.email && !!touched.email ? 'brand.red.900' : 'brand.green.900'}
                                                                errorBorderColor={'brand.red.900'}
                                                                name='email'
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                defaultValue={''}
                                                            />
                                                            <FormErrorMessage
                                                                color={'brand.red.900'}>{errors?.email?.toString()}</FormErrorMessage>
                                                        </FormControl>
                                                    )}
                                                </Field>
                                            </Box>
                                            <Box>
                                                <Field name='message'>
                                                    {({field, form}: any) => (
                                                        <FormControl isInvalid={!!errors.message && !!touched.message}
                                                                     minH={'100px'}>
                                                            <FormLabel
                                                                color={!!errors.message && !!touched.message ? 'brand.red.900' : 'brand.green.900'}
                                                                fontFamily={'sans-serif'}
                                                                textTransform={"uppercase"}
                                                                fontWeight={"bold"}
                                                            >
                                                                Message
                                                            </FormLabel>
                                                            <Textarea
                                                                color={'brand.green.900'}
                                                                focusBorderColor={!!errors.message && !!touched.message ? 'brand.red.900' : 'brand.green.900'}
                                                                errorBorderColor={'brand.red.900'}
                                                                name='message'
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                defaultValue={''}
                                                            />
                                                            <FormErrorMessage
                                                                color={'brand.red.900'}>{errors?.message?.toString()}</FormErrorMessage>
                                                        </FormControl>
                                                    )}
                                                </Field>
                                            </Box>
                                            <Flex w={"100%"} pt={5}>
                                                <Button
                                                    colorScheme='brand.green'
                                                    type={"submit"}
                                                    textTransform={'uppercase'}
                                                    w={"100%"}
                                                >
                                                    Send Message
                                                </Button>
                                            </Flex>
                                        </VStack>
                                    </form>
                                )}
                            </Formik>
                        </CardBody>
                    </Card>
                </VStack>
            }
        </Center>
    )
}