import { GraphQLClient, gql } from 'graphql-request'
import { slugify } from '../utils/api-helpers';
import { deserialize } from '../utils/slate-serializers';
import {createAsset} from "./AssetsAPI";

export async function getPostDetails(slug: string | undefined): Promise<any> {
    if (slug === undefined) {
        throw new Error("Oops, there was an issue here.  No post id defined")
    }
    const graphcms = new GraphQLClient(process.env.REACT_APP_GRAPHCMS_ENDPOINT!!, {
        headers: {
          "Authorization": process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN!!
        },
        jsonSerializer: {
          parse: JSON.parse,
          stringify: JSON.stringify,
        },
      });
      
      const query = gql`
        query Post($slug: String!) {
          post(where: {slug: $slug}){
            id,
            title,
            slug,
            author {
              name,
              image {
                url
              }
            },
            tags {
                name
            },
            createdAt,
            content {
              html
              raw
            },
            files(orderBy: title_ASC) {
              title
              description
              file {
                id,
                url(transformation: {document: {output: {format: jpg}}}),
                fullUrl: url,
                handle,
                fileName
              },
              id
              tags {
                id
                name
              }
              posts {
                id
              }
            },
            images {
              img {
                id,
                fileName,
                handle,
                url
              }
            },
            coverImage {
              url,
              fileName,
              handle
            }
          }
        }
      `;

      const {post} = await graphcms.request(query, {slug: slug});
      return post;
}

export async function getPosts(filters: Map<string, Map<string, string>>): Promise<any> {
    const graphcms = new GraphQLClient(process.env.REACT_APP_GRAPHCMS_ENDPOINT!!, {
        headers: {
          "Authorization": `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN!!}`
        }
      });

    let tags = undefined;

    if (filters && filters.get("Tag") && filters!!.get("Tag")!!.size > 0) {
        tags = Array.from(filters!!.get("Tag")!!.values())
    }

    const variableEntry = !tags ? "" : "($tags: [String!])";
    const whereClause = !tags ? "" : "where: {tags_some: {name_in: $tags}}, ";

    const query = gql`
      query GetPosts${variableEntry}{
        posts(${whereClause}orderBy: createdAt_DESC) {
            id,
            title,
            createdAt,
            slug,
            author {
                name,
                image {
                    url
                }
            },
            content {
                html
            },
            coverImage{
                url,
                fileName,
                handle
            },
            tags {
                name
            }
        }
    }`;

    const {posts} = await graphcms.request(query, {tags: tags});

    let filteredPosts = posts;

    if (filters && filters.get("BlogContent") && filters!!.get("BlogContent")!!.size > 0) {
        let blogContentFilter = filters!!.get("BlogContent")!!.values().next().value;
        filteredPosts = filteredPosts.filter((post: any) => post.title.toLowerCase().includes(blogContentFilter.toLowerCase()) || post.content.html.toLowerCase().includes(blogContentFilter.toLowerCase()))
    }

    return filteredPosts;
}

export async function deletePost(slug: string) {
    const url = process.env.REACT_APP_GRAPHCMS_ENDPOINT!!
    const graphcms = new GraphQLClient(url, {
        headers: { "Authorization" : `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN}`}
    })
    const res = await graphcms.request(
        `
        mutation($slug: String!) {
          deletePost(where: { slug: $slug}){
            id
          }
        }
        `,
        { slug: slug }
    )
    return res;
}

//TODO: Get Author from list (or person logged in as admin)

export async function createPost(post: any): Promise<string> {
    const title = post.title;
    const slug = slugify(title);
    const htmlString = post.content ? post.content : "";
    const document = new DOMParser().parseFromString(htmlString, 'text/html');
    const content = {"children":deserialize(document.body)};
    const url = process.env.REACT_APP_GRAPHCMS_ENDPOINT!!
    const graphcms = new GraphQLClient(url, {
        headers: { "Authorization" : `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN}`}
    })

    let coverImageId = '';
    let coverImageConnect = '';
    let coverImageVar = '';
    if (post.coverImage) {
        coverImageId = await createAsset(post.coverImage[0]);
        coverImageConnect = `, coverImage: {connect: {id: $coverImageId}}`;
        coverImageVar = ', $coverImageId: ID';
    }

    await graphcms.request(
        `
        mutation($title: String!, $datePublished: Date!, $content: RichTextAST!, $slug: String!${coverImageVar}) {
          createPost(
            data: { title: $title, datePublished: $datePublished, content: $content, author: {connect: {email: "drsty@aol.com"}}, slug: $slug${coverImageConnect}}
          ) {
            id,
            title,
            slug,
            datePublished
          }
        }
        `,
        { title: title, datePublished: new Date(Date.now()).toISOString(), content: content, slug: slug, coverImageId: coverImageId }
    )

    let {publishPost} = await graphcms.request(
        `mutation publishPost($slug: String) {
        publishPost(where: { slug: $slug}) {
            id,
            slug
            }
        }`,
        { slug: slug }
    )

    return publishPost;
}

export async function updatePost(oldSlug: string, post: any, oldCoverImageName: string): Promise<string> {
    const title = post.title;
    const slug = slugify(title);
    const htmlString = post.content ? post.content : "";
    const document = new DOMParser().parseFromString(htmlString, 'text/html');
    const content = {"children":deserialize(document.body)};
    const url = process.env.REACT_APP_GRAPHCMS_ENDPOINT!!
    const graphcms = new GraphQLClient(url, {
        headers: { "Authorization" : `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN}`}
    })

    let coverImageId = '';
    let coverImageConnect = '';
    let coverImageVar = '';
    if (post.coverImage) {
        if (post.coverImage.length === 0) {
            coverImageConnect = ', coverImage: {delete: true}';
        } else if (post.coverImage[0].filename !== oldCoverImageName) {
            coverImageId = await createAsset(post.coverImage[0]);
            coverImageConnect = `, coverImage: {connect: {id: $coverImageId}}`;
            coverImageVar = ', $coverImageId: ID';
        } else {
            //TODO: Delete old asset?
        }
    }

    await graphcms.request(
        `
        mutation($title: String!, $datePublished: Date!, $content: RichTextAST!, $slug: String!, $oldSlug: String!${coverImageVar}) {
          updatePost(
            data: { title: $title, datePublished: $datePublished, content: $content, author: {connect: {email: "drsty@aol.com"}}, slug: $slug${coverImageConnect}}
            where: { slug: $oldSlug }
          ) {
            slug
          }
        }
        `,
        { title: title, datePublished: new Date(Date.now()).toISOString(), content: content, slug: slug, oldSlug: oldSlug, coverImageId: coverImageId}
    )

    await graphcms.request(
        `mutation publishPost($slug: String) {
        publishPost(where: { slug: $slug}) {
            slug
            }
        }`,
        { slug: slug }
    )

    return slug;
}
