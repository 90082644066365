import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Image,
    Text,
    Flex,
    Box,
    Spacer,
    Tooltip,
    Center,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter, Button, AlertDialog, useDisclosure, Divider, HStack, Input
} from "@chakra-ui/react";
import React, {useRef, useState} from "react";
import {CloseIcon, DeleteIcon, DownloadIcon, EditIcon} from "@chakra-ui/icons";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {deleteFile, updateFile} from "../../api/FilesAPI";
import BlogAssetFormModal from "./BlogAssetFormModal";
import BlogTag from "./BlogTag";

export default function BlogFileModal({ ...props }: any) {
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);
    const deleteAlertModal = useDisclosure();
    const cancelRef = useRef(null);
    const updateFileModal = useDisclosure();
    const [deleteConfirmationValue, setDeleteConfirmationValue] = useState<string>(''); // State to hold the input value
    const [deleteConfirmed, setDeleteConfirmed] = useState<Boolean>(false)

    const handleDeleteConfirmationValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDeleteConfirmationValue(event.target.value)
        setDeleteConfirmed(event.target.value === getFileTitle(props.fileRef));
    };

    function getFileTitle(fileRef: any): string {
        return fileRef?.title && fileRef?.title.trim() !== '' ? fileRef?.title : fileRef?.file.fileName;
    }

    const handleDeleteFile = () => {
        deleteFile(props.fileRef?.id, props.fileRef?.file.id);
        if (props.handleFileDelete) {
            props.handleFileDelete();
        }
    }

    const handleDeleteAlertModalClose = () => {
        setDeleteConfirmationValue('')
        deleteAlertModal.onClose()
    }

    return (
        <>
        <BlogAssetFormModal
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={updateFileModal.isOpen}
            onClose={updateFileModal.onClose}
            asset={props.fileRef}
            assetType={'file'}
            //TODO: Clean up this logic to allow for multiple posts associated with file
            postConnectId={props.fileRef?.posts?.[0]?.id}
            updateAssetFunction={updateFile}
        />
        <AlertDialog
            isOpen={deleteAlertModal.isOpen}
            leastDestructiveRef={cancelRef}
            onClose={deleteAlertModal.onClose}
            motionPreset='slideInBottom'
            isCentered
        >
            <AlertDialogOverlay>
                <AlertDialogContent bg={'brand.green.50'}>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Delete Blog File
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        <Text>
                            Are you sure you want to delete file: "<strong>{getFileTitle(props.fileRef)}</strong>"? You can't undo this action afterwards.
                        </Text>
                        <Text mt={1}>
                            To confirm type: <i>{getFileTitle(props.fileRef)}</i>
                        </Text>
                        <Input
                            placeholder={`${getFileTitle(props.fileRef)}`}
                            value={deleteConfirmationValue}
                            onChange={handleDeleteConfirmationValueChange}
                        />
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={handleDeleteAlertModalClose} colorScheme={'brand.green'}>
                            Cancel
                        </Button>
                        <Button isDisabled={!deleteConfirmed} colorScheme='red' onClick={() => {
                            deleteAlertModal.onClose();
                            handleDeleteFile();
                        }} ml={3}>
                            Delete
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
        <Modal
            initialFocusRef={props.initialRef}
            finalFocusRef={props.finalRef}
            isOpen={props.isOpen}
            onClose={props.onClose}
            closeOnOverlayClick={false}
            scrollBehavior={'inside'}
            closeOnEsc={false}
            motionPreset='scale'
            size={'full'}
        >
            <ModalOverlay
                bg='blackAlpha.300'
                backdropFilter='blur(10px) hue-rotate(90deg)'/>
            <ModalContent bgColor={'brand.green.50'} maxW="56rem">
                <ModalHeader color={'brand.green.900'}>
                    <Flex alignItems={'stretch'}
                          justify="center"
                          align={'center'}>
                        <Center>
                            <Text fontSize={'sm'}>{getFileTitle(props.fileRef)}</Text>
                        </Center>
                        <Spacer />
                        <Box>
                            <Tooltip bgColor={'brand.blue.500'} color={'brand.green.50'} label={'Download File'}>
                                <a href={props.fileRef?.file.fullUrl} target="_blank" download={getFileTitle(props.fileRef)}>
                                    <DownloadIcon boxSize={4} color="brand.blue.500" mr={3}/>
                                </a>
                            </Tooltip>
                            <Tooltip zIndex={9999} bgColor={'brand.green.500'} color={'brand.green.50'} label={'Edit File'}>
                                <EditIcon boxSize={4} color="brand.green.500" mr={3} onClick={updateFileModal.onOpen}/>
                            </Tooltip>
                            <Tooltip bgColor={'brand.red.500'} color={'brand.green.50'} label={'Delete File'}>
                                <DeleteIcon boxSize={4} color="brand.red.500" mr={3} onClick={deleteAlertModal.onOpen} />
                            </Tooltip>
                            <Tooltip bgColor={'brand.green.300'} color={'brand.green.50'} label={'Close'}>
                                <CloseIcon boxSize={3} color={'brand.green.300'} onClick={props.onClose}/>
                            </Tooltip>
                        </Box>
                    </Flex>
                </ModalHeader>
                <ModalBody pb={6} margin={"auto"}>
                    <>
                        <HStack spacing={2} mb={'5'}>
                            {props.fileRef?.tags?.map((tag: any) => (
                                <React.Fragment key={tag.name}>
                                    <BlogTag editTag={false} updateFilters={()=>{}} size={'md'} variant={"solid"} name={tag.name} />
                                </React.Fragment>
                            ))}
                        </HStack>
                        <Divider mb={2}/>
                        {
                            props.fileRef?.description &&
                            <>
                                <Box mb={4}>
                                    <Text>
                                        {props.fileRef?.description}
                                    </Text>
                                    <Divider mt={2} mb={2}/>
                                </Box>
                            </>
                        }
                        <TransformWrapper>
                            <TransformComponent>
                                <Image src={props.fileRef?.file.url} alt={getFileTitle(props.fileRef)} />
                            </TransformComponent>
                        </TransformWrapper>
                    </>
                </ModalBody>
            </ModalContent>
        </Modal>
        </>
    )
}