import React, { useEffect, useState } from "react";
import { Wrap, Center, Spinner } from "@chakra-ui/react";
import { getTags } from "../../api/TagsAPI";
import BlogTag from "./BlogTag";
import { useAuth0 } from "@auth0/auth0-react";

export default function BlogTags(props: any) {
    const { isAuthenticated } = useAuth0();
    const [isLoading, setLoading] = useState(true);
    const [tags, setTags] = useState([]);
    const [err, setErr] = useState(null);
    const [refreshTags, setRefreshTags] = useState(true);

    const handleTagDelete = async (name: string) => {
        setLoading(true);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setRefreshTags(true);
    }

    useEffect(()=>{
        if (refreshTags) {
            getTags().then(res=>{
                setTags(res);
                setLoading(false);
                setRefreshTags(false);
            })
            .catch(err=>console.log(err))
        }
    },[refreshTags])

    return (
        <>
        {isLoading ?
            <Center>
                <Spinner />
            </Center>
            :
            <Wrap alignSelf={'flex-start'} p="12" mt={3} mb={3} >
            {tags?.map((tag: any) => (
                <React.Fragment key={tag.name}>
                    <BlogTag editTag={isAuthenticated} tagDeleteHandler={handleTagDelete} updateFilters={props.updateFilters} size={'lg'} variant={"solid"} name={tag.name} />
                </React.Fragment>
            ))}
            </Wrap>
        }
        </>
    )
}
