import {Box, Center, ChakraProvider, CSSReset, Flex, HStack, Image, Tooltip, useDisclosure, Wrap, WrapItem} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {createFile, getFiles} from "../../api/FilesAPI";
import {AddIcon} from "@chakra-ui/icons";
import BlogFile from "./BlogFile";
import BlogAssetFormModal from "./BlogAssetFormModal";
import {css} from "@emotion/css";

export default function BlogFiles() {
    const { isAuthenticated } = useAuth0();
    const [isLoading, setLoading] = useState(true);
    const [files, setFiles] = useState([]);
    const [err, setErr] = useState(null);
    const [refreshFiles, setRefreshFiles] = useState(true);
    const createFileModal = useDisclosure();
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);

    const handleFileDelete= async (id: string) => {
        setLoading(true);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setRefreshFiles(true);
    }

    const handleSuccessfulFileCreation = () => {
        setRefreshFiles(true);
    }

    useEffect(()=>{
        if (refreshFiles) {
            getFiles().then(res=>{
                setFiles(res);
                setLoading(false);
                setRefreshFiles(false);
            }).catch(err=>console.log(err))
        }
    },[refreshFiles])

    const customWrapStyles = {
        ".chakra-wrap__list": {
            margin: 0,
        },
    };

    return (
        <>
            <BlogAssetFormModal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={createFileModal.isOpen}
                onClose={createFileModal.onClose}
                asset={undefined}
                assetType={'file'}
                postConnectId={undefined}
                createAssetFunction={createFile}
                handleSuccessfulAssetCreation={handleSuccessfulFileCreation}
            />
            <Center>
                <Wrap alignSelf={'flex-start'} p="12" m={0} spacing={4} sx={customWrapStyles}>
                    {files?.map((fileRef: any, index: number) => (
                        <WrapItem key={fileRef.file.handle} m={0}>
                            <BlogFile fileRef={fileRef} handleFileDelete={handleFileDelete} />
                        </WrapItem>
                    ))}
                    <Flex
                        mx="auto"
                        align="center"
                        justify="center"
                        minW={'75px'}
                        minH={'125px'}
                        borderColor={'brand.green.900'}
                        borderWidth={2}
                        borderRadius={5}
                        cursor={'pointer'}
                        onClick={createFileModal.onOpen}>
                        <Center>
                            <Tooltip bgColor={'brand.green.500'} color={'brand.green.50'} label={'Add File'}>
                                <AddIcon />
                            </Tooltip>
                        </Center>
                    </Flex>
                </Wrap>
            </Center>
        </>
    )
}