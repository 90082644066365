import {Box, Button, Center, Flex, Heading, HStack, Input, Spinner, VStack} from "@chakra-ui/react";
import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect, useLayoutEffect, useState} from "react";
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import Editor from 'ckeditor5-custom-build';
import {getSiteConfig, upsertSiteConfig} from "../api/SiteConfigAPI";
import {astToHtml} from "../utils/slate-serializers";

export default function About() {
    const { isAuthenticated, user } = useAuth0();
    const [editAboutPage, setEditAboutPage] = useState(false);
    const [loading, setLoading] = useState(true);
    const [aboutHeader, setAboutHeader] = useState<any>({});

    //TODO: add proper toast messages for success and errors
    const updateAboutPage = () => {
        upsertSiteConfig(aboutHeader).then(res=> {
            setAboutHeader(res);
            setLoading(false);
        })
            .catch(err => {
                console.log(err);
                setLoading(false);
            })
        setEditAboutPage(false);
    }

    useLayoutEffect(()=>{
        if (loading) {
            getSiteConfig("aboutHeader").then(res=>{
                setAboutHeader(res);
                setLoading(false);
            })
                .catch(err=>{
                    console.log(err);
                    setLoading(false);
                })
        }
    },[loading])

    const updateHeader = (value: string) => {
        let updatedAboutHeader = aboutHeader;
        updatedAboutHeader.value = value;
        setAboutHeader(updatedAboutHeader);
    }

    return (
        <>
            {loading ?
                <Center mt={8}>
                    <Spinner />
                </Center>
                :
                <Box mt={8}>
                    <Flex maxW="900px" mx="auto" align="center" justify="center" px={5}>
                        <VStack>
                            {isAuthenticated && editAboutPage ?
                                <div style={{ maxWidth: "900px" }}>
                                    <CKEditor
                                        color={'brand.green.900'}
                                        editor={ Editor }
                                        data={aboutHeader.value}
                                        config={{
                                            forcePasteAsPlainText: false,
                                            pasteFromWordRemoveFontStyles: false,
                                            pasteFromWordRemoveStyles: false,
                                            allowedContent: true,
                                            extraAllowedContent: 'p(mso*,Normal)',
                                            pasteFilter: null
                                        }}
                                        onReady={ (editor: any) => {
                                            // console.log(editor);
                                        } }

                                        onChange={ ( event: any, editor: any ) => {
                                            const data = editor.getData();
                                            updateHeader(data);
                                        } }
                                        onBlur={ ( event: any, editor: any ) => {

                                        } }
                                        onFocus={ ( event: any, editor: any ) => {

                                        } }
                                    />
                                </div>
                                :
                                <div dangerouslySetInnerHTML={{__html: aboutHeader.value}} />
                            }
                            {isAuthenticated && (
                                editAboutPage ?
                                    <>
                                        <HStack>
                                            <Button bgColor={'brand.red.500'} onClick={() => setEditAboutPage(false)}>
                                                Cancel
                                            </Button>
                                            <Button colorScheme="brand.green" onClick={() => updateAboutPage()}>
                                                Save
                                            </Button>
                                        </HStack>
                                    </>
                                    :
                                    <>
                                        <Button colorScheme="brand.blue" onClick={() => setEditAboutPage(true)}>
                                            Edit About Page
                                        </Button>
                                    </>
                            )}
                        </VStack>
                    </Flex>
                </Box>
            }
        </>
    )
}