import { Center, useDisclosure, Spinner, useToast} from '@chakra-ui/react'
import React, {useEffect} from 'react';
import BlogPostFormModal from '../components/blog/BlogPostFormModal';
import { useParams } from "react-router-dom";
import { getPostDetails, deletePost } from '../api/PostsAPI';
import { useNavigate } from 'react-router-dom';
import BlogPostDetails from '../components/blog/BlogPostDetails';
import CustomToast from '../components/CustomToast';

export default function BlogPost() {
  const navigate = useNavigate();
  const {id} = useParams();
  const [isLoading, setLoading] = React.useState(true);
  const [post, setPost] = React.useState<any>({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const toast = useToast();

  const handleDeletePost = (title: string, slug: string) => {
    setLoading(true);
    toast({
      render: () => (
          <CustomToast
            title={`Deleting post: ${title}.`}
            status={'info'}
          />
        ),
      position: 'top-right'}
    );

    deletePost(slug)
      .then((res: any) => {
        toast({
          render: () => (
              <CustomToast
                title={`Successfully deleted post: ${title}.`}
                status={'success'}
              />
          ),
          position: 'top-right',
          onCloseComplete: () => {

          }
        });
        navigate('/blog');
      })
      .catch((err: Error) => {
        toast({
          render: () => (
              <CustomToast
                title={`Unable to delete post: ${title}. Please try again.`}
                status={'error'}
              />
            ),
          position: 'top-right'}
        )
        setLoading(false);
      })
  }

  const handleUpdatePost = (slug: string) => {
    navigate("/blog/" + slug)
  }

  useEffect(()=>{
    getPostDetails(id)
    .then(res=>{
      setPost(res);
      setLoading(false);
    })
    .catch(err=>console.log(err))
  },[id])

  return (
    <>
      <BlogPostFormModal 
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        handleSubmitClick={handleUpdatePost}
        post={post} />
      <div>
          <BlogPostDetails onOpen={onOpen} handleDeletePost={handleDeletePost} post={post} isLoading={isLoading} />
      </div>
    </>
  )
}