import { Outlet } from "react-router";
import Header from "../components/header/Header";
import ScrollToTop from "../components/ScrollToTop";

export default function MainLayout() {
    return (
        <>
            <Header />
            <Outlet />
            <ScrollToTop />
        </>
    )
}