import {GraphQLClient} from "graphql-request";

export async function deleteAsset(id: string) {
    const url = process.env.REACT_APP_GRAPHCMS_ENDPOINT!!
    const graphcms = new GraphQLClient(url, {
        headers: { "Authorization" : `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN}`}
    })
    const res = await graphcms.request(
        `
        mutation($id: ID!) {
          deleteAsset(where: { id: $id }){
            id
          }
        }
        `,
        { id: id }
    )

    return {res};
}

export async function createAsset(file: any): Promise<any> {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN!!}`);
    const url = process.env.REACT_APP_GRAPHCMS_ENDPOINT!!;
    const graphcms = new GraphQLClient(url, {
        headers: { "Authorization" : `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN}`}
    });

    let form = new FormData();
    form.set('fileUpload', file.file);

    let res = await fetch(`${process.env.REACT_APP_GRAPHCMS_ENDPOINT!!}/upload`, {
        method: 'POST',
        body: form,
        headers: headers
    })
    .then(response => response.json())
    .then(async data => {
        await graphcms.request(
            `mutation publishAsset($id: ID!) {
                        publishAsset(where: {id: $id}) {
                            id
                          }
                        }`,
    { id: data.id }
        )
        return data;
    })
    return res.id;
}