import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({ 
    zIndices: {
      overlay: 10000,
      modal: 10001,
      toast: 10002,
      toastManager: 10003,
      tooltip: 10004,
    },
    components: {
      Input: {
          baseStyle: {
              field: {
                  bg: 'brand.green.50',
                  borderColor: 'brand.green.500',
                  border: '2px solid',
                  ':hover': {
                      border: '2px solid',
                      borderColor: 'brand.green.500'
                  },
                  _groupHover: {
                      border: '2px solid',
                      borderColor: 'brand.green.500'
                  },
                  ':focus': {
                      border: '2px solid',
                      borderColor: 'brand.green.500'
                  }
              }
          },
          defaultProps: {}
      }
    },
    styles: {
        global: (props: any) => ({
            body: {
                fontFamily: 'sans-serif',
                boxSizing: 'border-box',
                bgColor: 'brand.green.50',
                color: 'brand.green.900'
            },
            h1: {
              fontSize: '3xl',
              fontWeight: 'bold',
            },
            h2: {
              fontSize: '2xl',
              fontWeight: 'bold',
            },
            h3: {
              fontSize: 'lg'
            },
            h4: {
              fontSize: 'md'
            },
            ol: {
              marginLeft: '15px'
            },
            ul: {
              marginLeft: '15px'
            }
        })
    },
    colors: {
        brand: {
          green: {
            50: "#FFFEFE",
            100: "#CDD8D7",
            200: "#6A8B88",
            300: "#517875",
            500: "#073f3a",
            600: "#042522",
            700: "#073f3a",
            800: "#073f3a",
            900: "#042522"
          },
          teal: {
            500: "#008080",
            900: "#006666"
          },
          red: {
            500: "#f8c0c5",
            900: "#ef717c"
          },
          blue: {
            500: "#003d46",
            900: "#003138"
          },
          yellow: {
            500: "#ffff99",
            900: "#ffff47"
          },
          gold: {
            100: "#bb823e",
          },
          lightgreen: {
            50: "#D1CECE",
            100: "#F0D2A2",
            200: "#6A8B88",
            300: "#517875",
            500: "#cef1c3",
            600: "#042522",
            700: "#073f3a",
            800: "#073f3a",
            900: "#073f3a"
          }
        }
    }
 })

export default theme
