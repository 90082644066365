import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react';
import Auth0ProviderWithHistory from './auth/Auth0ProviderWithHistory';
import theme from './theme'
import '@fontsource/alex-brush'
import {
  BrowserRouter,
} from "react-router-dom";
import {GlobalStateProvider} from "./GlobalState";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <Auth0ProviderWithHistory>
        <ChakraProvider theme={theme}>
            <GlobalStateProvider>
                <App />
            </GlobalStateProvider>
        </ChakraProvider>
    </Auth0ProviderWithHistory>
  </ BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
