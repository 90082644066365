import { useAuth0 } from "@auth0/auth0-react";
import { Button, propNames } from '@chakra-ui/react';

export const LoginButton = (props: any) => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: window.location,
      },
    });
  };

  return (
    <Button
        as={'a'}
        fontSize={props.fontSize}
        fontWeight={400}
        variant={'link'}
        cursor={'pointer'}
        onClick={handleLogin}>
        Sign In
    </Button>
  );
};