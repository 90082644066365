import { useAuth0 } from "@auth0/auth0-react";
import { Text } from '@chakra-ui/react';

export const LogoutButton = (props: any) => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      returnTo: window.location.origin,
    });
  };

  return (
    <Text cursor={'pointer'} onClick={handleLogout} fontSize={props.fontSize}>
      Log Out
    </Text>
  );
};