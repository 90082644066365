import React, { useEffect, useState } from 'react';
import {
  Flex,
  Avatar,
  Link as ChakraLink,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  Stack,
  useMediaQuery,
  Center,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerHeader,
  useBreakpointValue,
  DrawerCloseButton,
  Text,
  VStack,
  Divider,
  Heading,
  DrawerFooter
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from '../buttons/LoginButton';
import { LogoutButton } from '../buttons/LogoutButton';
import BlogPostFormModal from '../blog/BlogPostFormModal';
import {Link, useLocation} from 'react-router-dom';
import TodoList from "../todo/TodoList";
import {useGlobalState} from "../../GlobalState";

export default function Header() {
  const [isLessThan480px] = useMediaQuery('(max-width: 480px)');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const blogPostFormModal = useDisclosure();
  const { isAuthenticated, user } = useAuth0();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const width = useBreakpointValue({ base: '100%', lg: '80%' });
  let listener: any = null;
  const [scrollState, setScrollState] = useState(false);
  const { state, dispatch } = useGlobalState();
  const location = useLocation();
  const currentUrl = location.pathname;

  //TODO: Remove this after adding stricter user checking
  console.log(JSON.stringify(user, null, 2));

  console.log(currentUrl);

  useEffect(() => {
    listener = document.addEventListener("scroll", e => {
      if (document.scrollingElement !== null) {
        var scrolled = document.scrollingElement.scrollTop;
        setScrollState((scrolled >= 120));
      }
    })
    return () => {
      document.removeEventListener("scroll", listener)
    }
  }, [scrollState])

  const applyFamilyTreeFilter = (treeKey: string) => {
    let filters = new Map();
    filters.set("Tag", new Map());
    filters.get("Tag").set(treeKey, treeKey);
    dispatch({ type: 'SET_FILTERS', payload: filters });
  }

  const isHeaderBgFilled = (): boolean => {
    return scrollState || ['contact-us', 'about'].some(searchString => currentUrl.includes(searchString))
  }

  return (
    <>
      <BlogPostFormModal 
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={blogPostFormModal.isOpen}
        onClose={blogPostFormModal.onClose}
        />
      <Flex 
          align={'center'} 
          justify={'center'} 
          pos={'sticky'}
          py={15}
          px={isLessThan480px ? [15, 10, 10] : ""}
          top={0}
          as={'nav'}
          bg={isHeaderBgFilled() ? "brand.green.500" : "brand.green.50"}
          w={"100%"} 
          h={"15vh"}
          zIndex={9999}
          boxShadow={isHeaderBgFilled() ? 'base' : 'none'}
          >
          <Center w={"100%"} justifyContent={"space-evenly"}>
            <Drawer placement={'left'} onClose={onClose} isOpen={isOpen} size={isLessThan480px ? '' : 'md'}>
              <DrawerOverlay />
              <DrawerContent bgColor={"brand.green.50"} color={'brand.green.900'}>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth='3px'>
                  {user ? <>{user?.name}</> : <>Welcome to our Genealogy Blog!</>}
                </DrawerHeader>
                <DrawerBody fontSize={'1.25rem'}>
                  <Stack pt={'10px'}>
                    <ChakraLink as={Link} to={'/'} onClick={onClose}>Home</ChakraLink>
                    <ChakraLink as={Link} to={'/about'} onClick={onClose}>About</ChakraLink>
                    <ChakraLink as={Link} to={'/blog'} onClick={onClose}>Blog</ChakraLink>
                    <ChakraLink as={Link} to={'/contact-us'} onClick={onClose}>Contact Us</ChakraLink>
                    <Divider borderColor={"brand.green.900"} p={'5px'}/>
                    {isAuthenticated && (
                      <>
                        <Heading size={'md'} pt={'10px'}>Family Trees</Heading>
                        <ChakraLink as={Link} to={'/blog'} onClick={() => {applyFamilyTreeFilter("Dana's Line"); onClose();}}>Dana Styron's Family Tree</ChakraLink>
                        <ChakraLink as={Link} to={'/blog'} onClick={() => {applyFamilyTreeFilter("Mike's Line"); onClose();}}>Michael Merritt's Family Tree</ChakraLink>
                        <Divider borderColor={'brand.green.900'} p={'5px'}/>
                        <Heading size={'md'} pt={'10px'}>Admin Options</Heading>
                        <ChakraLink onClick={blogPostFormModal.onOpen}>Create New Post</ChakraLink>
                        <Divider borderColor={'brand.green.900'} p={'5px'}/>
                        <TodoList />
                      </>
                    )}
                  </Stack>
                </DrawerBody>
                <DrawerFooter>
                  {isAuthenticated ? 
                  <LogoutButton fontSize={'1.25rem'} />
                  :
                  <LoginButton fontSize={'1.25rem'} />
                  }
                </DrawerFooter>
              </DrawerContent>
            </Drawer>
            
            <Center>
              <HamburgerIcon color={isHeaderBgFilled() ? 'brand.green.50' : 'brand.green.900'}  onClick={onOpen} aria-label='Open Side Menu' fontSize={isLessThan480px ? "1.25rem" : "2rem"} />
            </Center>

            <Center>
              <Link to={"/"}>
                <VStack>
                  <Text color={isHeaderBgFilled() ? 'brand.green.50' : 'brand.green.900'} fontSize={isLessThan480px ? "" : "1.5rem"} fontFamily={'sans-serif'}>Exploring Our Branches</Text>
                  <Text color={isHeaderBgFilled() ? 'brand.green.50' : 'brand.green.900'} fontFamily={'Alex Brush, cursive'} fontSize={isLessThan480px ? "" : "2rem"}>- A Genealogy Journey -</Text>
                </VStack>
              </Link>
            </Center>

            {!isLessThan480px && (
            <Center>
              <Stack direction={'row'} spacing={7}>
                  {isAuthenticated ?
                      <Menu>
                          <MenuButton
                          as={Button}
                          rounded={'full'}
                          variant={'link'}
                          cursor={'pointer'}
                          minW={0}>
                          <Avatar
                              size={'sm'}
                              src={'https://avatars.dicebear.com/api/initials/sassy-sty-genealog.svg'}
                          />
                          </MenuButton>
                          <MenuList alignItems={'center'} bgColor={'brand.green.100'}>
                              <br />
                              <Center>
                                  <Avatar
                                  size={'2xl'}
                                  src={'https://avatars.dicebear.com/api/initials/sassy-sty-genealog.svg'}
                                  />
                              </Center>
                              <br />
                              <Center>
                                  <p>{user?.name}</p>
                              </Center>
                              <br />
                              <MenuDivider />
                              <MenuItem onClick={blogPostFormModal.onOpen} bgColor={'brand.green.100'}>Create New Post</MenuItem>
                              <MenuItem bgColor={'brand.green.100'}><LogoutButton/></MenuItem>
                          </MenuList>
                      </Menu>
                  :
                      <LoginButton fontSize={'md'} />
                  }
              </Stack>
            </Center>
            )}
            
          </Center>
        </Flex>
    </>
  );
}