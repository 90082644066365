import {Flex, HStack, Input, Text} from "@chakra-ui/react";
import {CheckIcon, DeleteIcon, EditIcon} from "@chakra-ui/icons";
import React, {useState} from "react";
import {deleteTodoItem, updateTodoItem} from "../../api/TodoAPI";
import {AutoResizeTextarea} from "../AutoResizeTextarea";

export default function TodoItem({handleTodoItemUpdate, handleTodoItemDeletion, todoItem}: any) {
    const [todoValue, setTodoValue] = useState(todoItem?.content);
    const [editTodo, setEditTodo] = useState(false);

    const handleEditClick = () => {
        setEditTodo(true);
    }

    const handleUpdateSubmit = () => {
        setEditTodo(false);
        todoItem.content = todoValue;
        updateTodoItem(todoItem);
        handleTodoItemUpdate();
        //TODO: add toasts for status
    }

    const handleDeletion = () => {
        deleteTodoItem(todoItem.id);
        handleTodoItemDeletion();
    }

    return (
        <>
            <HStack>
                <Flex p={6} justifyContent="space-between">
                    {editTodo ?
                        <AutoResizeTextarea value={todoValue} onChange={(event: any) => setTodoValue(event.target.value)} mr={2}/>
                        :
                        <Text fontSize={'sm'} mr={2}>{todoValue}</Text>
                    }

                    <Flex w="10px" >
                        <DeleteIcon color="brand.red.500" mr="2" onClick={handleDeletion}/>
                        {editTodo ?
                            <CheckIcon onClick={handleUpdateSubmit}/>
                            :
                            <EditIcon onClick={handleEditClick} />
                        }

                    </Flex>


                </Flex>
            </HStack>
        </>
    )
}