import { GraphQLClient, gql } from 'graphql-request'

export async function deleteTag(name: string) {
    const url = process.env.REACT_APP_GRAPHCMS_ENDPOINT!!
    const graphcms = new GraphQLClient(url, {
        headers: { "Authorization" : `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN}`}
    })
    const res = await graphcms.request(
        `
        mutation($name: String!) {
          deleteTag(where: { name: $name}){
            name
          }
        }
        `,
        { name: name }
    )
}

export async function createTag(name: string): Promise<string> {
    const url = process.env.REACT_APP_GRAPHCMS_ENDPOINT!!
    const graphcms = new GraphQLClient(url, {
        headers: { "Authorization" : `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN}`}
    })

    await graphcms.request(
        `
        mutation($name: String!) {
          createTag(
            data: { name: $name}
          ) {
            name
          }
        }
        `,
        { name: name }
    )

    await graphcms.request(
        `mutation publishTag($name: String) {
        publishTag(where: { name: $name}) {
            name
            }
        }`,
        { name: name }
    )

    return name;
}

export interface UpsertTag {
  name: string,
  deleteFromResource: boolean
}

//resourceName = something like 'post' or 'file' or 'image'
export async function upsertTags(tags: UpsertTag[], resourceName: string, resourceId: string) {
  const url = process.env.REACT_APP_GRAPHCMS_ENDPOINT!!
  const graphcms = new GraphQLClient(url, {
      headers: { "Authorization" : `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN}`}
  })

  // Rate of calls to the hygraph API is 5 per second
  const rateLimit = 3;
  let callCount = 0;

  for (const tag of tags) {
    if ((callCount + 1) % rateLimit === 0 && callCount < tags.length - 1) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
    let updateResourceClause = tag.deleteFromResource ? '{disconnect: {id: $resourceId}}' : '{connect: {where: {id: $resourceId}}}'
    await graphcms.request(`
      mutation($name: String!, $resourceId: ID!) {
        upsertTag(
          upsert: {create: {name: $name, ${resourceName}s: {connect: {id: $resourceId}}}, update: {name: $name, ${resourceName}s: ${updateResourceClause}}}
          where: {name: $name} 
        ) {
          name
        }
      }
      `,{ name: tag.name, resourceId: resourceId });

    await graphcms.request(
      `mutation publishTag($name: String!) {
        publishTag(where: { name: $name}) {
          name
          }
      }`,
      { name: tag.name }
    )
  }
}

export async function getTags(): Promise<any> {
    const graphcms = new GraphQLClient(process.env.REACT_APP_GRAPHCMS_ENDPOINT!!, {
        headers: {
          "Authorization": `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN!!}`
        }
      });
      
    const query = gql`
    {
        tags{
            name,
        }
    }`;

    const {tags} = await graphcms.request(query);
    return tags;
}