import { useRef } from "react";
import { deleteTag } from "../../api/TagsAPI";
import {Button, Tag, TagCloseButton, TagLabel, AlertDialog, AlertDialogContent, AlertDialogOverlay, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, useDisclosure} from '@chakra-ui/react';

interface IBlogTag {
    name: string;
    editTag: boolean;
    size: string;
    variant: string;
    updateFilters: Function;
    tagDeleteHandler?: Function;
}

export default function BlogTag(props: IBlogTag) {
    const deleteAlertModal = useDisclosure();
    const cancelRef = useRef(null);

    const handleDeleteTag = () => {
        deleteTag(props.name);
        if (props.tagDeleteHandler) {
            props.tagDeleteHandler();
        }
    }

    return (
        <>
        <AlertDialog
            isOpen={deleteAlertModal.isOpen}
            leastDestructiveRef={cancelRef}
            onClose={deleteAlertModal.onClose}
            motionPreset='slideInBottom'
            isCentered
            >
            <AlertDialogOverlay>
                <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    Delete Blog Tag
                </AlertDialogHeader>

                <AlertDialogBody>
                    Are you sure you want to delete tag: "<strong>{props.name}</strong>"? You can't undo this action afterwards.
                </AlertDialogBody>

                <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={deleteAlertModal.onClose}>
                    Cancel
                    </Button>
                    <Button colorScheme='brand.green' onClick={() => {
                        deleteAlertModal.onClose();
                        handleDeleteTag();
                    }} ml={3}>
                    Delete
                    </Button>
                </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
        <Tag size={props.size} variant={props.variant} fontSize={".85rem"} sx={{bg: 'brand.green.100', color: "brand.green.500", cursor: "pointer"}} key={props.name} onClick={() => {props.updateFilters("Tag", {key: props.name, value: props.name})}}>
            <TagLabel>#{props.name}</TagLabel>
            {props.editTag ?
            <TagCloseButton onClick={deleteAlertModal.onOpen}/>
            : 
            <></>
            }
        </Tag>
    </>
    )
}
