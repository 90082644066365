import {gql, GraphQLClient} from "graphql-request";

export async function getTodoList(): Promise<[]> {
    const graphcms = new GraphQLClient(process.env.REACT_APP_GRAPHCMS_ENDPOINT!!, {
        headers: {
            "Authorization": `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN!!}`
        }
    });

    const query = gql`
    {
        todoItemList{
            id,
            content
        }
    }`;

    const {todoItemList} = await graphcms.request(query);
    return todoItemList;
}

export async function deleteTodoItem(id: string) {
    const url = process.env.REACT_APP_GRAPHCMS_ENDPOINT!!
    const graphcms = new GraphQLClient(url, {
        headers: { "Authorization" : `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN}`}
    })
    const res = await graphcms.request(
        `
        mutation($id: ID!) {
          deleteTodoItem(where: { id: $id}){
            id
          }
        }
        `,
            { id: id }
    )
}

export async function updateTodoItem(todoItem: any): Promise<any> {
    const url = process.env.REACT_APP_GRAPHCMS_ENDPOINT!!
    const graphcms = new GraphQLClient(url, {
        headers: { "Authorization" : `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN}`}
    });

    await graphcms.request(
        `
        mutation MyMutation($content: String!, $id: ID,) {
          updateTodoItem(data: {content: $content}, where: {id: $id}) {
            id
          }
        }`, {content: todoItem.content, id: todoItem.id}
    )

    await graphcms.request(
        `mutation publishTodoItem($id: ID!) {
        publishTodoItem(where: { id: $id}) {
                id
            }
        }`,
        { id: todoItem.id }
    )

    return todoItem.id;
}

export async function createTodoItem(todoContent: string): Promise<any> {
    const url = process.env.REACT_APP_GRAPHCMS_ENDPOINT!!
    const graphcms = new GraphQLClient(url, {
        headers: { "Authorization" : `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN}`}
    })

    const {createTodoItem} = await graphcms.request(
            `
        mutation($content: String!) {
          createTodoItem(
            data: { content: $content}
          ) {
            id,
            content
          }
        }
        `,
            {content: todoContent}
        ).catch((err: any) => {
            console.log(err);
        })

    await graphcms.request(
        `mutation publishTodoItem($id: ID!) {
        publishTodoItem(where: { id: $id}) {
                id
            }
        }`,
        { id: createTodoItem.id }
    )

    return createTodoItem.id;
}