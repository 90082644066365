import { useEffect, useRef, useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    useToast,
    position,
    Tooltip
} from "@chakra-ui/react";
import { getTags } from "../../api/TagsAPI";
import CustomToast from "../CustomToast";
import BlogAssetForm from "./BlogAssetForm";
import voca from 'voca';

export default function BlogAssetFormModal({ ...props }: any) {
    const updateBlogAsset: boolean = props.asset;
    const submitRef = useRef<HTMLInputElement>(null);
    const [tags, setTags] = useState([]);
    const [submitButtonTurnedOn, setSubmitButtonTurnedOn] = useState(updateBlogAsset);
    const toast = useToast();

    useEffect(() => {
        getTags()
            .then(res => {
                setTags(res);
            })
            .catch(err => console.log(err))
    },[])

    const setSubmitButtonState = (state: boolean) => {
        setSubmitButtonTurnedOn(state);
    }

    const handleSubmitButtonClick = () => {
        submitRef.current?.click();
        props.onClose();

        toast({
            render: () => (
                <CustomToast
                    title={`${updateBlogAsset ? "Updating" : "Creating"} ${props.assetType}!...`}
                    status={'info'}
                />
            ),
            position: 'top-right'}
        )
    }

    return (
        <Modal
            initialFocusRef={props.initialRef}
            finalFocusRef={props.finalRef}
            isOpen={props.isOpen}
            onClose={props.onClose}
            closeOnOverlayClick={false}
            scrollBehavior={'inside'}
            closeOnEsc={false}
            size={'xs'}
            isCentered
        >
            <ModalOverlay
                bg='blackAlpha.300'
                backdropFilter='blur(10px) hue-rotate(90deg)'/>
            <ModalContent bgColor={'brand.green.50'} maxW="56rem">
                <ModalHeader color={'brand.green.900'}>{updateBlogAsset ? `Update ${voca.capitalize(props.assetType)}` : `Create New ${voca.capitalize(props.assetType)}`}</ModalHeader>
                <ModalBody pb={6}>
                    <BlogAssetForm setSubmitButtonState={setSubmitButtonState} postConnectId={props.postConnectId} handleSuccessfulAssetCreation={props.handleSuccessfulAssetCreation} createAssetFunction={props.createAssetFunction} updateAssetFunction={props.updateAssetFunction} assetType={props.assetType} handleSubmitClick={props.handleSubmitClick} submitRef={submitRef} asset={props.asset} allTags={tags} />
                </ModalBody>
                <ModalFooter>
                    <Button mr={3} onClick={props.onClose} bgColor={'brand.green.100'}>Cancel</Button>
                    <Tooltip label={!submitButtonTurnedOn ? "Please fix errors above" : ""} placement={"top"} bg={'brand.red.500'} color={'brand.green.900'}>
                        <Button disabled={!submitButtonTurnedOn} colorScheme='brand.green' onClick={handleSubmitButtonClick}>{updateBlogAsset ? "Update" : "Create"}</Button>
                    </Tooltip>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}