import React from 'react';
import {
    FormControl,
    Input,
    FormLabel,
    Box,
    useToast, FormErrorMessage
} from '@chakra-ui/react';
import FileUploader from './FileUploader';
import { Formik, Field } from 'formik';
import {createPost, updatePost} from '../../api/PostsAPI';
import BlogContentEditor from './BlogContentEditor';
import {astToHtml} from '../../utils/slate-serializers';
import { UpsertTag, upsertTags } from '../../api/TagsAPI';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteTag,
  AutoCompleteCreatable
} from "@choc-ui/chakra-autocomplete"; 
import CustomToast from '../CustomToast';
import { useNavigate } from 'react-router';
import * as yup from "yup";

export default function BlogPostForm({
                                     setSubmitButtonState,
                                     post=null,
                                     submitRef,
                                     handleSubmitClick=()=>{},
                                     allTags=[]}: any) {
  const initialRef = React.useRef(null);  
  const updateBlogPost: boolean = post;
  const toast = useToast();
  const navigate = useNavigate();

    const validationSchema = yup.object({
        title: yup.string().required("Title is required").matches(/^[A-Za-z0-9\s\W_]*[A-Za-z0-9]$/, 'Title can only end in a number or letter.')
    });

  const defaultContentValue = '<p>This is the section for your blog content like a Word doc with <strong>rich</strong> text or <em>italic</em> text, etc...!</p><p></p><p>Since it&#39;s rich text, you can do things like turn a selection of text <strong>bold</strong>, or add a semantically rendered block quote in the middle of the page, like this:</p><blockquote>A wise quote.</blockquote><p>Write your blog content here!</p>'

  const tagItems = allTags.map((tag: any) => {return tag.name});

  let content: any = defaultContentValue;
  let postTags: string[] = [];
  if (post !== null && post !== undefined) {
    content = astToHtml(post.content.raw);
    if (post.tags) {
      postTags = post.tags.map((tag: any) => {return tag.name});
    }
  }

  const [selectedTags, setSelectedTags] = React.useState<string[]>(postTags);

  const buildExistingCoverImageFileRef = (coverImage: any) => {
    let coverImageFileRef: any = [];
    if (coverImage) {
      coverImageFileRef = [{fileName: coverImage.fileName, url: coverImage.url}];
    }
    return coverImageFileRef;
  }

  const handleSelectedTagsChange = (selectedTags?: any) => {
    if (selectedTags) {
      setSelectedTags(selectedTags);
    }
  }

  const buildUpsertTags = (selectedTags: string[]): UpsertTag[] => {
    const updatedTags: UpsertTag[] = [];
    for (const postTag of postTags) {
      if (!updatedTags.find((obj) => obj.name === postTag)) {
        if (!selectedTags.includes(postTag)) {
          updatedTags.push({name: postTag, deleteFromResource: true});
        } else {
          updatedTags.push({name: postTag, deleteFromResource: false});
        } 
      }
    }

    for (const selectedTag of selectedTags) {
      if (!updatedTags.find((obj) => obj.name === selectedTag)) {
        updatedTags.push({name: selectedTag, deleteFromResource: false});
      }
    }
    return updatedTags;
  }

  const displayErrorToast = (updateBlogPost: boolean, title: string) => {
    toast({
      render: () => (
          <CustomToast
              title={`Unable to ${updateBlogPost ? 'update' : 'create'} post: ${title}.  Please try again.`}
              status={'error'}
          />
      ),
      position: 'top-right'}
    )
  }

  const displayTagAssociationErrorToast = (title: string) => {
    toast({
      render: () => (
          <CustomToast
              title={`Unable to add tag(s) to post: ${title}.  Please try to add tag from Update Post.`}
              status={'warn'}
          />
      ),
      position: 'top-right'}
    )
  }

  const displaySuccessToast = (updateBlogPost: boolean, title: string) => {
    toast({
      render: () => (
          <CustomToast
              title={`Successfully ${updateBlogPost ? 'updated' : 'created'} post: ${title}`}
              status={'success'}
          />
      ),
      position: 'top-right'}
    )
  }

  return (
    <>
      <Formik
        initialValues={{validateOnMount: true, title: updateBlogPost ? post.title : '', content: content}}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            if (updateBlogPost) {
              updatePost(post.slug, values, post.coverImage?.handle)
                  .then((slug) => {
                    actions.setSubmitting(false);
                    handleSubmitClick(slug);
                    if (selectedTags) {
                      upsertTags(buildUpsertTags(selectedTags), 'post', post.id)
                      .then((res: any) => {
                        displaySuccessToast(updateBlogPost, values.title);
                        navigate(0);
                      }).catch((err: any) => {
                        console.log(err);
                        displayTagAssociationErrorToast(values.title);
                        navigate(0);
                      });
                    } else {
                      displaySuccessToast(updateBlogPost, values.title);
                      navigate(0);
                    }
                  })
                  .catch((err: any) => {
                    console.log(err);
                    displayErrorToast(updateBlogPost, values.title);
                  });
            } else {
              createPost(values)
                  .then((res: any) => {
                    actions.setSubmitting(false);
                    handleSubmitClick(res.slug);
                    if (selectedTags) {
                      upsertTags(buildUpsertTags(selectedTags), 'post', res.id)
                      .then((tagRes: any) => {
                        displaySuccessToast(updateBlogPost, values.title);
                        navigate('/blog/' + res.slug);
                      })
                      .catch((err: any) => {
                        console.log(err);
                        displayTagAssociationErrorToast(values.title);
                        navigate('/blog/' + res.slug);
                      });
                    } else {
                      displaySuccessToast(updateBlogPost, values.title);
                      navigate('/blog/' + res.slug);
                    }
                  })
                  .catch((err: any) => {
                    console.log(err);
                    displayErrorToast(updateBlogPost, values.title);
                  });
            }
          }, 1000)
        }}
      >
        {({ handleSubmit,
              handleChange,
              handleBlur,
              errors,
              touched ,
            dirty,
            isValid
          }) => (
        <form onSubmit={handleSubmit} >
          <Box mb={4}>
            <Field name='title'>
            {({ field, form }: any) => (
              <FormControl isInvalid={!!errors.title && !!touched.title}>
                <FormLabel color={!!errors.title && !!touched.title ? 'brand.red.900' : 'brand.green.900'} fontFamily={'sans-serif'}>Title of Post</FormLabel>
                    <Input color={'brand.green.900'} focusBorderColor={!!errors.title && !!touched.title ? 'brand.red.900' : 'brand.green.900'} errorBorderColor={'brand.red.900'} name='title' onChange={(e: any) => {handleChange(e); setSubmitButtonState((updateBlogPost || dirty) && isValid);}} onBlur={(e: any) => {handleBlur(e); setSubmitButtonState((updateBlogPost || dirty) && isValid);}} ref={initialRef} placeholder={updateBlogPost ? post.title : 'Blog Post Title'} defaultValue={updateBlogPost ? post.title : ''}/>
                  <FormErrorMessage color={'brand.red.900'}>{errors?.title?.toString()}</FormErrorMessage>
              </FormControl>

            )}
            </Field>
          </Box>

          <Field name='coverImage'>
          {({ field, form }: any) => (
            <FileUploader title={'Cover Image'} existingFiles={buildExistingCoverImageFileRef(post?.coverImage)} acceptedFileTypes={["image/*"]} allowMultiple={false} name='coverImage' required={false}/>
          )}
          </Field>

          <Box mb={4}>
            <Field name='tags'>
            {({ field, form }: any) => (
              <FormControl isRequired={false}>
                <FormLabel color={'brand.green.900'} fontFamily={'sans-serif'}>Tags</FormLabel>
                <AutoComplete restoreOnBlurIfEmpty={false} openOnFocus multiple closeOnSelect={false} onChange={handleSelectedTagsChange} creatable defaultValues={selectedTags}>
                    <AutoCompleteInput variant="outline" focusBorderColor={'brand.green.900'}>
                      {({ tags }) =>
                        tags.map((tag, tid) => (
                          <AutoCompleteTag
                            key={tid}
                            label={tag.label}
                            onRemove={tag.onRemove}
                            color={'brand.green.900'}
                            bg={'brand.green.100'}
                          />
                        ))
                      }
                    </AutoCompleteInput>
                    <AutoCompleteList bg={'brand.green.100'}>
                      {tagItems.map((value: any, label: any) => (
                        <AutoCompleteItem
                          key={`option-${value}`}
                          value={value}
                          _selected={{ bg: "brand.green.300" }}
                          _focus={{ bg: "brand.green.300" }}
                        >
                          {value}
                        </AutoCompleteItem>
                      ))}
                      <AutoCompleteCreatable>
                        {({ value }) => <span>Create {value}</span>}
                      </AutoCompleteCreatable>
                    </AutoCompleteList>
                </AutoComplete>
              </FormControl>
            )}
            </Field>
          </Box>

          <Field name='content'>
          {({ field, form }: any) => (
            <BlogContentEditor content={content} onChange={field.onChange} name='content' defaultValue={defaultContentValue}/>
          )}
          </Field>
          <button ref={submitRef} type="submit" style={{ display: 'none' }} disabled={!(dirty && isValid)} />
        </form>
        )}
      </Formik>
    </>
  )
}