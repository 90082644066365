import React, {createContext, useContext, useReducer} from 'react';

type GlobalState = {
    filters: Map<string, Map<string, string>>;
};

type GlobalAction =
    | { type: 'CLEAR_FILTERS'; payload: null }
    | { type: 'SET_FILTERS'; payload: Map<string, Map<string, string>> };

const initialState: GlobalState = {
    filters: new Map(),
};

const GlobalStateContext = createContext<{
    state: GlobalState;
    dispatch: React.Dispatch<GlobalAction>;
}>({
    state: initialState,
    dispatch: () => undefined,
});

interface Props {
    children: React.ReactNode;
}

const GlobalStateProvider: React.FC<Props> = ({ children }: Props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <GlobalStateContext.Provider value={{ state, dispatch }}>
            {children}
        </GlobalStateContext.Provider>
    );
};

const useGlobalState = () => useContext(GlobalStateContext);

const reducer = (state: GlobalState, action: GlobalAction): GlobalState => {
    switch (action.type) {
        case 'SET_FILTERS':
            return { ...state, filters: action.payload };
        case 'CLEAR_FILTERS':
            return { ...state, filters: new Map()};
        default:
            return state;
    }
};


export { GlobalStateProvider, useGlobalState };
