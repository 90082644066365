

// npm install react-filepond filepond --save
import React, { useState, useEffect } from "react";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import { useField } from 'formik';
import { FormControl, FormLabel } from '@chakra-ui/react';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginImageResize,
  FilePondPluginImageCrop,
  FilePondPluginImageTransform
);

function convertExistingFiles(existingFiles: any[any]): any[] {
    let fileList: any[] = [];
    if (existingFiles) {
        for (var existingFile of existingFiles) {
            fileList.push({
                source: existingFile.url,
                options: {
                    type: 'remote',
                }
            })
        }
    }
    return fileList;
}

// Our app
export default function FileUploader({
  existingFiles,
  required,
  defaultValue,
  acceptedFileTypes,
  allowMultiple,
  title,
  ...props
}: any) {
    const [files, setFiles] = useState(convertExistingFiles(existingFiles));
    const [field, meta, helpers] = useField<{}>(props);
    const { setValue } = helpers;
    let pond: any = null;

    return (
      <FormControl isRequired={required}>
          <FormLabel color={'brand.green.900'} fontFamily={'sans-serif'}>{title}</FormLabel>
          <FilePond
            files={files}
            ref={(ref) => {
              pond = ref;
            }}
            acceptedFileTypes={acceptedFileTypes}
            fileValidateTypeDetectType={(source, type) =>
              // Note: we need this here to activate the file type validations and filtering
              new Promise((resolve, reject) => {
                // Do custom type detection here and return with promise
                resolve(type);
              })
            }
            allowFileEncode
            allowImageTransform
            imagePreviewHeight={400}
            imageCropAspectRatio={"1:1"}
            imageResizeTargetWidth={100}
            imageResizeTargetHeight={100}
            imageResizeMode={"cover"}
            imageTransformOutputQuality={50}
            imageTransformOutputQualityMode="optional"
            imageTransformBeforeCreateBlob={(canvas: any) =>
              new Promise((resolve) => {
                // Do something with the canvas, like drawing some text on it
                const ctx = canvas.getContext("2d");
                ctx.font = "48px serif";
                ctx.fillText("Hello world", 10, 50);

                // console.log("imageTransformBeforeCreateBlob", ctx, canvas);

                // return canvas to the plugin for further processing
                resolve(canvas);
              })
            }
            imageTransformAfterCreateBlob={(blob: any) =>
              new Promise((resolve) => {
                // do something with the blob, for instance send it to a custom compression alogrithm
                // console.log("imageTransformAfterCreateBlob", blob);

                // return the blob to the plugin for further processing
                resolve(blob);
              })
            }
            onupdatefiles={fileItems => {
                    let fileList = fileItems.map((fileItem) => {
                        return fileItem;
                    });
                    setFiles(fileList);
                    setValue(fileList);
                }
            }
            instantUpload={false}
            allowProcess={false}
            allowMultiple={allowMultiple}
            name="files"
            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
            credits={false}
          />
      </FormControl>
    )
}
