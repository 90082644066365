import {Field, Form, Formik} from "formik";
import {UpsertTag, upsertTags} from "../../api/TagsAPI";
import CustomToast from "../CustomToast";
import {Box, FormControl, FormErrorMessage, FormLabel, Input, useToast} from "@chakra-ui/react";
import FileUploader from "./FileUploader";
import voca from 'voca';
import {
    AutoComplete, AutoCompleteCreatable,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
    AutoCompleteTag
} from "@choc-ui/chakra-autocomplete";
import React from "react";
import * as yup from 'yup';

const validationSchema = yup.object({
    title: yup.string().matches(/^[A-Za-z0-9\s\W_]*[A-Za-z0-9]$/, 'Title can only end in a number or letter.')
});

export default function BlogAssetForm({
                                      setSubmitButtonState,
                                      asset = null,
                                      submitRef,
                                      handleSubmitClick=()=>{},
                                      assetType,
                                      allTags=[],
                                      postConnectId=null,
                                      createAssetFunction,
                                      updateAssetFunction,
                                      handleSuccessfulAssetCreation}: any) {
    const initialRef = React.useRef(null);
    const updateBlogAsset: boolean = asset;
    const toast = useToast();

    const tagItems = allTags.map((tag: any) => {return tag.name});

    let assetTags: string[] = [];
    if (asset !== null && asset !== undefined) {
        if (asset.tags) {
            assetTags = asset.tags.map((tag: any) => {return tag.name});
        }
    }

    const [selectedTags, setSelectedTags] = React.useState<string[]>(assetTags);

    const handleSelectedTagsChange = (selectedTags?: any) => {
        if (selectedTags) {
            setSelectedTags(selectedTags);
        }
    }

    const buildExistingAssetContentFileRef = (assetContent: any) => {
        let assetContentFileRef: any = [];
        if (assetContent && assetContent.file) {
            assetContentFileRef = [{fileName: assetContent.file.fileName, url: assetContent.file.fullUrl}];
        }
        return assetContentFileRef;
    }

    const buildUpsertTags = (selectedTags: string[]): UpsertTag[] => {
        const updatedTags: UpsertTag[] = [];
        for (const assetTag of assetTags) {
            if (!updatedTags.find((obj) => obj.name === assetTag)) {
                if (!selectedTags.includes(assetTag)) {
                    updatedTags.push({name: assetTag, deleteFromResource: true});
                } else {
                    updatedTags.push({name: assetTag, deleteFromResource: false});
                }
            }
        }

        for (const selectedTag of selectedTags) {
            if (!updatedTags.find((obj) => obj.name === selectedTag)) {
                updatedTags.push({name: selectedTag, deleteFromResource: false});
            }
        }
        return updatedTags;
    }

    const handleSuccess = (title: string) => {
        toast({
            render: () => (
                <CustomToast
                    title={`Successfully ${updateBlogAsset ? 'updated' : 'created'} ${assetType}: ${title}`}
                    status={'success'}
                />
            ),
            position: 'top-right'}
        )
        handleSuccessfulAssetCreation();
    }

    return (
        <>
            <Formik
                initialValues={{title: updateBlogAsset ? asset?.title : '', description: updateBlogAsset ? asset?.description : '', postConnectId: postConnectId}}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        try {
                            if (updateBlogAsset) {
                                updateAssetFunction(asset.id, values, asset).then((id: any) => {
                                    actions.setSubmitting(false);
                                    handleSubmitClick(id);
                                    if (selectedTags) {
                                        upsertTags(buildUpsertTags(selectedTags), assetType, id.id)
                                            .then((res: any) => {
                                                handleSuccess(values.title);
                                            });
                                    } else {
                                        handleSuccess(values.title);
                                    }
                                });
                            } else {
                                createAssetFunction(values).then((id: any) => {
                                    actions.setSubmitting(false);
                                    handleSubmitClick(id);
                                    if (selectedTags) {
                                        upsertTags(buildUpsertTags(selectedTags), assetType, id.id)
                                            .then((res: any) => {
                                                handleSuccess(values.title);
                                            });
                                    } else {
                                        handleSuccess(values.title);
                                    }
                                });
                            }
                        } catch(err: any) {
                            console.log(err)

                            toast({
                                render: () => (
                                    <CustomToast
                                        title={`Unable to ${updateBlogAsset ? 'update' : 'create'} ${assetType}: ${values.title}.  Please try again.`}
                                        status={'error'}
                                    />
                                ),
                                position: 'top-right'}
                            )
                        }
                    }, 1000)
                }}
            >
                {({ handleSubmit,
                      handleChange,
                      handleBlur,
                      errors,
                      touched ,
                      dirty,
                      isValid
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <Box mb={4}>
                            <Field name='title'>
                                {({ field, form }: any) => (
                                    <FormControl isInvalid={!!errors.title && !!touched.title}>
                                        <FormLabel color={!!errors.title && !!touched.title ? 'brand.red.900' : 'brand.green.900'} fontFamily={'sans-serif'}>Title</FormLabel>
                                        <Input color={'brand.green.900'} focusBorderColor={!!errors.title && !!touched.title ? 'brand.red.900' : 'brand.green.900'} errorBorderColor={'brand.red.900'} name='title' onChange={(e: any) => {handleChange(e); setSubmitButtonState(isValid);}} onBlur={(e: any) => {handleBlur(e); setSubmitButtonState(isValid);}} ref={initialRef} placeholder={updateBlogAsset ? asset.title : 'Title'} defaultValue={updateBlogAsset ? asset.title : ''}/>
                                        <FormErrorMessage color={'brand.red.900'}>{errors?.title?.toString()}</FormErrorMessage>
                                    </FormControl>

                                )}
                            </Field>
                        </Box>

                        <Box mb={4}>
                            <Field name='description'>
                                {({ field, form }: any) => (
                                    <FormControl isInvalid={!!errors.description && !!touched.description}>
                                        <FormLabel color={!!errors.description && !!touched.description ? 'brand.red.900' : 'brand.green.900'} fontFamily={'sans-serif'}>Description</FormLabel>
                                        <Input color={'brand.green.900'} focusBorderColor={!!errors.description && !!touched.description ? 'brand.red.900' : 'brand.green.900'} errorBorderColor={'brand.red.900'} name='description' onChange={(e: any) => {handleChange(e); setSubmitButtonState(isValid);}} onBlur={(e: any) => {handleBlur(e); setSubmitButtonState(isValid);}} ref={initialRef} placeholder={updateBlogAsset ? asset.description : 'Description'} defaultValue={updateBlogAsset ? asset.description : ''}/>
                                        <FormErrorMessage color={'brand.red.900'}>{errors?.description?.toString()}</FormErrorMessage>
                                    </FormControl>

                                )}
                            </Field>
                        </Box>

                        <Field name={assetType}>
                            {({ field, form }: any) => (
                                <FileUploader title={`${voca.capitalize(assetType)}`} existingFiles={buildExistingAssetContentFileRef(asset)} allowMultiple={false} name={assetType} required={true}/>
                            )}
                        </Field>

                        <Box mb={4}>
                            <Field name='tags'>
                                {({ field, form }: any) => (
                                    <FormControl isRequired={false}>
                                        <FormLabel color={'brand.green.900'} fontFamily={'sans-serif'}>Tags</FormLabel>
                                        <AutoComplete restoreOnBlurIfEmpty={false} openOnFocus multiple closeOnSelect={false} onChange={handleSelectedTagsChange} creatable defaultValues={selectedTags}>
                                            <AutoCompleteInput variant="outline" colorScheme={'brand.green'} focusBorderColor={'brand.green.900'}>
                                                {({ tags }) =>
                                                    tags.map((tag, tid) => (
                                                        <AutoCompleteTag
                                                            key={tid}
                                                            label={tag.label}
                                                            onRemove={tag.onRemove}
                                                            color={'brand.green.900'}
                                                            bg={'brand.green.100'}
                                                        />
                                                    ))
                                                }
                                            </AutoCompleteInput>
                                            <AutoCompleteList bg={'brand.green.100'}>
                                                {tagItems.map((value: any, label: any) => (
                                                    <AutoCompleteItem
                                                        key={`option-${value}`}
                                                        value={value}
                                                        _selected={{ bg: "brand.green.300" }}
                                                        _focus={{ bg: "brand.green.300" }}
                                                    >
                                                        {value}
                                                    </AutoCompleteItem>
                                                ))}
                                                <AutoCompleteCreatable>
                                                    {({ value }) => <span>Create {value}</span>}
                                                </AutoCompleteCreatable>
                                            </AutoCompleteList>
                                        </AutoComplete>
                                    </FormControl>
                                )}
                            </Field>
                        </Box>
                        <button ref={submitRef} type="submit" style={{ display: 'none' }} />
                    </Form>
                )}
            </Formik>
        </>
    )
}