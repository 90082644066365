import {Box, HStack, Text, VStack} from "@chakra-ui/react";
import {CloseIcon} from "@chakra-ui/icons";
import React from "react";

export default function BlogFilter(props: any) {
    return (
        <Box borderColor={'brand.gold.100'} borderWidth={3} borderRadius={5} bg={'brand.green.100'} color={'brand.green.500'}>
            <VStack pr={1} pb={1} pl={1} pt={1.5} >
                <HStack>
                    <Text fontSize={'sm'} as={'b'} >{props.filterValue}</Text>
                    <CloseIcon fontSize={'sm'} onClick={() => props.filterRemovalHandler(props.filterType, props.filterKey)}/>
                </HStack>
                <Text fontSize={'xs'} color={'brand.gold.100'} style={{width: "100%", marginTop: "0"}} >{props.filterType}</Text>
            </VStack>
        </Box>
    )
}
