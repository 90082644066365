import React, {useEffect, useState} from "react";
import {createTodoItem, getTodoList} from "../../api/TodoAPI";
import {Badge, Button, Center, Spinner, Stack, Text, Textarea, VStack} from "@chakra-ui/react";
import TodoItem from "./TodoItem";
import {AutoResizeTextarea} from "../AutoResizeTextarea";

export default function TodoList() {
    const [refreshTodoList, setRefreshTodoList] = useState(true);
    const [loading, setLoading] = useState(true);
    const [todoList, setTodoList] = useState([]);
    const [newTodoItemValue, setNewTodoItemValue] = useState('');
    const [error, setError] = useState(null);

    const handleTodoItemDeletion = () => {
        setRefreshTodoList(true);
    }

    const handleTodoItemUpdate = () => {
        setRefreshTodoList(true);
    }

    const handleTodoItemCreation = (event: any) => {
        event.preventDefault();
        createTodoItem(newTodoItemValue).then((res: any) => {
            setNewTodoItemValue('');
            setRefreshTodoList(true);
        })
    }

    useEffect(()=>{
        if (refreshTodoList) {
            getTodoList().then(res=>{
                setTodoList(res);
                setLoading(false);
                setRefreshTodoList(false);
            })
            .catch(err=>{
                console.log(err);
                setLoading(false);
                setError(err);
            })
        }
    },[refreshTodoList])

    return (
        <>
            {loading &&
                <Center>
                    <Spinner />
                </Center>
            }
            {error &&
                <Center>
                    <Text>Unable to load To-Do List</Text>
                </Center>
            }
            <>
                <VStack>
                    <Text fontSize={'lg'}>To-Do List</Text>
                    {!todoList.length || !todoList ?
                    <Badge
                        colorScheme="brand.blue"
                        variant="outline"
                        borderRadius="4"
                        p='4' m='5'
                    >No To-Dos!</Badge>
                        :
                    <VStack>
                        {todoList.map((todoItem: any) => (
                            <React.Fragment key={todoItem.id}>
                                <TodoItem handleTodoItemUpdate={handleTodoItemUpdate} handleTodoItemDeletion={handleTodoItemDeletion} todoItem={todoItem} />
                            </React.Fragment>
                        ))}
                    </VStack>
                    }
                    <form onSubmit={handleTodoItemCreation}>
                        <Stack spacing={5}>
                            <AutoResizeTextarea
                                mt={5}
                                value={newTodoItemValue}
                                placeholder="Enter your To-Do.."
                                onChange={(e: any)=>setNewTodoItemValue(e.target.value)} />
                            <Button colorScheme="brand.green" type="submit">Add To-Do</Button>
                        </Stack>
                    </form>
                </VStack>
            </>
        </>
    )
}