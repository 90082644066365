import { useState } from "react";
import BlogTags from "../components/blog/BlogTags";
import BlogPostList from "../components/blog/BlogPostList";
import { Center, Box, Tabs, Tab, TabPanels, TabPanel, TabList, useBreakpointValue, Flex, VStack, Stack, Text } from "@chakra-ui/react";
import BlogHeader from "../components/blog/BlogHeader";
import { useAuth0 } from "@auth0/auth0-react";
import BlogFiles from "../components/blog/BlogFiles";
import {useGlobalState} from "../GlobalState";

export default function Blog() {
    const [tabIndex, setTabIndex] = useState(0);
    const width = useBreakpointValue({ base: '100%', lg: '70%' });
    const isAuthenticated = useAuth0();
    const pxValue = useBreakpointValue({ base: 4, md: 8 });
    const signinMessageFontSize = useBreakpointValue({ base: '3xl', md: '4xl' });
    const { state, dispatch } = useGlobalState();

    const handleTabsChange = (index: number) => {
        setTabIndex(index)
    }

    const updateFilters = (filterType: string, filter: any) => {
        if (!state.filters.has(filterType)) {
            state.filters.set(filterType, new Map());
        }

        state.filters.get(filterType)?.set(filter.key, filter.value);
        dispatch({ type: 'SET_FILTERS', payload: new Map(state.filters) });
    }

    const handleFilterRemoval = (filterType: string, filterKey: string) => {
        state.filters.get(filterType)?.delete(filterKey);
        dispatch({ type: 'SET_FILTERS', payload: new Map(state.filters) });
    }

    const clearFilters = () => {
        dispatch({ type: 'CLEAR_FILTERS', payload: null});
    }

    return (
        <>  
            {isAuthenticated ? 
            <>
            <BlogHeader activeTab={tabIndex} filters={state.filters} filterRemovalHandler={handleFilterRemoval} updateFilters={updateFilters}/>
            <Box 
                py={10}
                px={[10, 20, 20]}
                top={0}
                left={0}
                right={0}
                w={width} 
                mx={"auto"} 
                pl={'5%'}
                >
                <Tabs variant='soft-rounded' isFitted colorScheme='brand.lightgreen' index={tabIndex} onChange={handleTabsChange} >
                    <TabList fontFamily={'sans-serif'}>
                        <Tab>Posts</Tab>
                        <Tab>Files</Tab>
                        {/*<Tab isDisabled>Categories</Tab>*/}
                        <Tab>Tags</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <Center><BlogPostList updateFilters={updateFilters} clearFilters={clearFilters}/></Center>
                        </TabPanel>
                        <TabPanel>
                            <Center><BlogFiles /></Center>
                        </TabPanel>
                        <TabPanel>
                            <Center><BlogTags updateFilters={updateFilters}/></Center>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
            </>
            :
            <>
            <Flex
                w={'full'}
                minH={'80vh'}
                backgroundSize={'cover'}
                backgroundPosition={'center center'}>
                    <VStack
                        w={'full'}
                        justify={'center'}
                        px={pxValue}
                        bgGradient={'linear(to-b, brand.green.50, brand.green.500)'}>
                        <Stack maxW={'2xl'} align={'flex-start'} spacing={6}>
                            <Text
                                color={'#031c19'}
                                fontWeight={700}
                                lineHeight={1.2}
                                fontFamily={'house-script, sans-serif'}
                                fontSize={signinMessageFontSize}>
                                Please sign in to view this Blog.
                            </Text>
                        </Stack>
                    </VStack>
                </Flex>
            </>
            }
        </>
    )
}
