import { useEffect, useRef, useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    useToast,
    position,
    Tooltip
} from "@chakra-ui/react";
import BlogPostForm from "./BlogPostForm";
import { getTags } from "../../api/TagsAPI";
import CustomToast from "../CustomToast";

export default function BlogPostFormModal({ ...props }: any) {
    const updateBlogPost: boolean = props.post;
    const submitRef = useRef<HTMLInputElement>(null);
    const [tags, setTags] = useState([]);
    const [submitButtonTurnedOn, setSubmitButtonTurnedOn] = useState(updateBlogPost);
    const toast = useToast();

    useEffect(() => {
      getTags()
        .then(res => {
          setTags(res);
        })
        .catch(err => console.log(err))
    },[])

    const setSubmitButtonState = (state: boolean) => {
        setSubmitButtonTurnedOn(state);
    }

    const handleSubmitButtonClick = () => {
      submitRef.current?.click(); 
      props.onClose();

      toast({
        render: () => (
           <CustomToast
              title={`${updateBlogPost ? "Updating" : "Creating"} blog post!...`}
              status={'info'}
           />
         ),
        position: 'top-right'}
      )
    }

    return (
        <Modal
          initialFocusRef={props.initialRef}
          finalFocusRef={props.finalRef}
          isOpen={props.isOpen}
          onClose={props.onClose}
          closeOnOverlayClick={false}
          
          scrollBehavior={'inside'}
          closeOnEsc={false}
          isCentered
        >
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px) hue-rotate(90deg)'/>
          <ModalContent bgColor={'brand.green.50'} maxW="56rem">
            <ModalHeader color={'brand.green.900'}>{updateBlogPost ? "Update Blog Post" : "Create New Blog Post"}</ModalHeader>
            <ModalBody pb={6}>
              <BlogPostForm setSubmitButtonState={setSubmitButtonState} handleSubmitClick={props.handleSubmitClick} submitRef={submitRef} post={props.post} allTags={tags} />
            </ModalBody>
            <ModalFooter>
              <Button mr={3} onClick={props.onClose} bgColor={'brand.green.100'}>Cancel</Button>
              <Tooltip label={!submitButtonTurnedOn ? "Please fix errors above" : ""} placement={"top"} bg={'brand.red.500'} color={'brand.green.900'}>
                <Button disabled={!submitButtonTurnedOn} colorScheme='brand.green' onClick={handleSubmitButtonClick}>{updateBlogPost ? "Update" : "Create"}</Button>
              </Tooltip>
            </ModalFooter>
          </ModalContent>
      </Modal>
    )
}