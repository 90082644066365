import {gql, GraphQLClient} from "graphql-request";

export async function getSiteConfig(configId: string) {
    const graphcms = new GraphQLClient(process.env.REACT_APP_GRAPHCMS_ENDPOINT!!, {
        headers: {
            "Authorization": `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN!!}`
        }
    });

    const query = gql`
        query SiteConfig($configId: String!){
            siteConfig(where: {configId: $configId}) {
                configId,
                value
            }
        }`;

    const {siteConfig} = await graphcms.request(query, {configId: configId});
    return siteConfig;
}

export async function deleteSiteConfig(configId: string) {
    const url = process.env.REACT_APP_GRAPHCMS_ENDPOINT!!
    const graphcms = new GraphQLClient(url, {
        headers: { "Authorization" : `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN}`}
    })
    const res = await graphcms.request(
        `
        mutation($configId: String!) {
          deleteSiteConfig(where: { configId: $configId }){
            id
          }
        }
        `,
        { configId: configId }
    )

    return {res};
}

export async function upsertSiteConfig(siteConfig: any): Promise<any> {
    const url = process.env.REACT_APP_GRAPHCMS_ENDPOINT!!
    const graphcms = new GraphQLClient(url, {
        headers: { "Authorization" : `Bearer ${process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN}`}
    });

    await graphcms.request(
        `
        mutation MyMutation($configId: String!, $value: String!,) {
          upsertSiteConfig(
            upsert: {create: {configId: $configId, value: $value}, update: {configId: $configId, value: $value}}
            where: {configId: $configId}
          ) {
            configId,
            value
          }
        }`, {value: siteConfig.value, configId: siteConfig.configId}
    )

    await graphcms.request(
        `mutation publishSiteConfig($configId: String!) {
            publishSiteConfig(where: { configId: $configId}) {
                configId
            }
        }`,
        { configId: siteConfig.configId }
    )

    return siteConfig.configId;
}