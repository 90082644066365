import { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import './App.css';
import Landing from './pages/Landing';
import ContactUs from "./pages/ContactUs";
import Blog from "./pages/Blog";
import NotFoundPage from "./pages/404";
import MainLayout from "./layouts/MainLayout";
import BlogPost from "./pages/BlogPost"
import { withAuthenticationRequired } from "@auth0/auth0-react";
import About from "./pages/About";
import FamilyTree from "./pages/FamilyTree";

function App() {
  const AuthBlog = withAuthenticationRequired(Blog);
  const AuthBlogPost = withAuthenticationRequired(BlogPost);
  const AuthFamilyTree = withAuthenticationRequired(FamilyTree);

  useEffect(() => {
    document.title = "SassySty Genealogy";  
  }, []);

  return (
    <Routes>
      <Route element={ <MainLayout/> }>
        <Route path="/" element={ <Landing/> } />
        <Route path="/about" element={ <About/> } />
        <Route path="/contact-us" element={ <ContactUs/> } />
        <Route path="/blog" element={ <AuthBlog/> } />
        <Route path="/blog/:id" element={ <AuthBlogPost/>} />
        <Route path="/family-tree/:id" element={ <AuthFamilyTree />} />
        <Route path="*" element={ <NotFoundPage/> } />
      </Route>
    </Routes>
  );
}

export default App;
