import React, {useState} from 'react';
import {
  Flex,
  Breadcrumb,
  HStack,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
  Image,
  useDisclosure,
  chakra,
  Spacer,
  Link as ChakraLink,
  Heading,
  Button,
  AlertDialog,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Center,
  Input,
  Tooltip, Skeleton, SkeletonText, SkeletonCircle, Text
} from '@chakra-ui/react'
import {EditIcon, DeleteIcon, ChevronRightIcon, AddIcon} from '@chakra-ui/icons';
import moment from 'moment';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import BlogTag from './BlogTag';
import { astToHtml } from '../../utils/slate-serializers';
import { truncate } from 'lodash';
import BlogAssetFormModal from "./BlogAssetFormModal";
import {createFile} from "../../api/FilesAPI";
import {useNavigate} from "react-router";
import BlogFile from "./BlogFile";

const convertRelativeTime = (date: any) => {
  return moment(date).format('LL');
};

const BlogPostDetails = ({onOpen, handleDeletePost, post, isLoading}: any) => {
  const { isAuthenticated } = useAuth0();
  const cancelRef = React.useRef(null);
  const deleteAlertModal = useDisclosure();
  const createFileModal = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const navigate = useNavigate();
  const [deleteConfirmationValue, setDeleteConfirmationValue] = useState<string>(''); // State to hold the input value
  const [deleteConfirmed, setDeleteConfirmed] = useState<Boolean>(false)

  const handleDeleteConfirmationValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeleteConfirmationValue(event.target.value)
    setDeleteConfirmed(event.target.value === post.slug);
  };

  const handleFileDelete = async (id: string) => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    navigate(0)
  }

  const handleDeleteAlertModalClose = () => {
    setDeleteConfirmationValue('')
    deleteAlertModal.onClose()
  }

  return (
    <>
    <AlertDialog
        isOpen={deleteAlertModal.isOpen}
        leastDestructiveRef={cancelRef}
        onClose={deleteAlertModal.onClose}
        motionPreset='slideInBottom'
        isCentered
      >
      <AlertDialogOverlay>
        <AlertDialogContent bg={'brand.green.50'}>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            Delete Blog Post
          </AlertDialogHeader>

          <AlertDialogBody>
            <Box>
              <Text>
                Are you sure you want to delete "{post.title}"? You can't undo this action afterwards.
              </Text>
              <Text mt={1}>
                To confirm type: <i>{post.slug}</i>
              </Text>
              <Input
                  placeholder={`${post.slug}`}
                  value={deleteConfirmationValue}
                  onChange={handleDeleteConfirmationValueChange}
              />
            </Box>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={handleDeleteAlertModalClose} colorScheme={'brand.green'}>
              Cancel
            </Button>
            <Button isDisabled={!deleteConfirmed} colorScheme='red' onClick={() => {
              deleteAlertModal.onClose();
              handleDeletePost(post.title, post.slug, deleteConfirmationValue);
            }} ml={3}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
      <BlogAssetFormModal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={createFileModal.isOpen}
        onClose={createFileModal.onClose}
        asset={undefined}
        assetType={'file'}
        postConnectId={post.id}
        createAssetFunction={createFile}
        //TODO: update the success to reload the post without refreshing the whole page
        handleSuccessfulAssetCreation={() => navigate(0)}
      />
      <div>
        <Flex maxW="900px" mx="auto" align="center" justify="center" px={5}>
          <Box w="100%" py={5} color={'brand.green.900'}>
            <Skeleton isLoaded={!isLoading}>
              <Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to={"/blog"} >Blog</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink as={Link} to={''}>{truncate(post?.title)}</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>
            </Skeleton>
            <Box py={6}>
              <Skeleton isLoaded={!isLoading}>
                <Heading as="h1" size="xl" fontFamily={'sans-serif'} color={'brand.green.500'}>
                  {post?.title}
                </Heading>
              </Skeleton>
              <Skeleton isLoaded={!isLoading}>
                <Heading
                  as="h2"
                  fontWeight="normal"
                  size="sm"
                  mt={2}
                  fontStyle="italic"
                  color={'brand.green.500'}
                >
                  {post?.subtitle}
                </Heading>
              </Skeleton>

              <Box my={6}>
                <Flex align="center">
                  <Flex align="center" justify="between">
                    <SkeletonCircle h={10} isLoaded={!isLoading}>
                      <Image
                        h={10}
                        fit="cover"
                        rounded="full"
                        src={post?.author?.image?.url}
                        alt={`Avatar of ${post?.author?.name}`}
                      />
                    </SkeletonCircle>
                    <Flex align="flex-start" direction="column">
                      <Skeleton isLoaded={!isLoading}>
                        <ChakraLink mx={3} fontWeight="bold" color={'brand.green.300'}>
                          {post?.author?.name}
                        </ChakraLink>
                      </Skeleton>
                      <Skeleton isLoaded={!isLoading}>
                        <chakra.span mx={3} fontSize="sm" color={'brand.green.300'}>
                          {convertRelativeTime(post?.createdAt)}
                        </chakra.span>
                      </Skeleton>
                    </Flex>
                  </Flex>

                  <Spacer />

                  {(isAuthenticated && !isLoading) ?
                  <>
                    <Button colorScheme="brand.green" mr={3} onClick={onOpen}>
                      <EditIcon />
                    </Button>

                    <Button onClick={deleteAlertModal.onOpen} colorScheme="red">
                      <DeleteIcon />
                    </Button>
                  </>
                  :
                  <></>
                  }
                </Flex>
              </Box>

              <Center style={{ marginBottom: '1rem' }}>
                <Skeleton isLoaded={!isLoading}>
                  <Image
                      boxSize={'xs'}
                      src={post?.coverImage?.url || 'https://loremflickr.com/1280/720'}
                      borderRadius="md"
                      alt={post?.title}
                  />
                </Skeleton>
              </Center>
              <Skeleton isLoaded={!isLoading}>
                <HStack spacing={2} mb={'5'}>
                  {post.tags?.map((tag: any) => (
                      <React.Fragment key={tag.name}>
                        <BlogTag editTag={false} updateFilters={()=>{}} size={'md'} variant={"solid"} name={tag.name} />
                      </React.Fragment>
                  ))}
                </HStack>
              </Skeleton>
              <SkeletonText isLoaded={!isLoading}>
                <div dangerouslySetInnerHTML={{__html: astToHtml(post.content?.raw)}} />
              </SkeletonText>
              <Center>
                <Skeleton isLoaded={!isLoading}>
                  <HStack
                      spacing={2}
                      mb={'5'}
                      pt={10}>
                    {post.files?.map((fileRef: any) => (
                        <React.Fragment key={fileRef.file.handle}>
                          <BlogFile fileRef={fileRef} handleFileDelete={handleFileDelete}/>
                        </React.Fragment>
                    ))}
                    <Flex
                        mx="auto"
                        align="center"
                        justify="center"
                        px={5}
                        minW={'75px'}
                        minH={'125px'}
                        borderColor={'brand.green.900'}
                        borderWidth={2}
                        borderRadius={5}
                        cursor={'pointer'}
                        onClick={createFileModal.onOpen}>
                      <Center>
                        <Tooltip bgColor={'brand.green.500'} color={'brand.green.50'} label={'Add File'}>
                          <AddIcon />
                        </Tooltip>
                      </Center>
                    </Flex>
                  </HStack>
                </Skeleton>
              </Center>
            </Box>
          </Box>
        </Flex>
      </div>
    </>
  )
}

export default BlogPostDetails;
