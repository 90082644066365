import React, {useEffect, useState} from 'react';
import { 
  Divider, 
  Text, 
  Button,
  useDisclosure, 
  Center, 
  VStack,
  Box,
  Spinner
} from '@chakra-ui/react';
import BlogPostFormModal from './BlogPostFormModal';
import { useAuth0 } from "@auth0/auth0-react";
import BlogPostCard from './BlogPostCard';
import { getPosts } from '../../api/PostsAPI';
import {useGlobalState} from "../../GlobalState";

export default function BlogPostList(props: any) {
  const {isAuthenticated} = useAuth0();
  const blogPostFormModal = useDisclosure();
  const initialRef = React.useRef(null)
  const finalRef = React.useRef(null)
  const [posts, setPosts] = useState([])
  const [err, setErr] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const { state, dispatch } = useGlobalState();

  useEffect(()=>{
    getPosts(state.filters)
    .then(res=>{
      setPosts(res);
      setLoading(false);
    })
    .catch(err=>console.log(err))
  },[state.filters])

  return (
    <>
      <BlogPostFormModal 
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={blogPostFormModal.isOpen}
        onClose={blogPostFormModal.onClose}
      />
      {err ? 
        <>
          <Center>
            <VStack>
              <Text>Oops, something went wrong on our end... Please refresh the page to try again!</Text>
            </VStack>
          </Center>
        </>
      :
      isLoading ?
        <>
          <Center>
            <VStack>
              <Spinner />
            </VStack>
          </Center> 
        </>
        :
        <>
          {posts && posts.length < 1 ?
              <Center>
                <VStack>
                  <Box>No Posts Found</Box>
                  {props.selectedTag === "" ?
                      <>
                        <Text>Nothing Here Yet</Text>
                        {isAuthenticated ?
                            <Button onClick={blogPostFormModal.onOpen}>Create New Post</Button>
                            : <></>
                        }
                      </>
                      : <Button onClick={props.clearFilters}>Clear Filters</Button>
                  }
                </VStack>
              </Center>
            :
            <>
              <VStack>
              {
                posts?.map((post: any) => (
                  <React.Fragment key={post.title}>
                    <BlogPostCard post={post} updateFilters={props.updateFilters} />
                    <Divider borderColor={"brand.green.900"} p={'5px'} />
                  </React.Fragment>
                ))
              } 
              </VStack>
            </>
          }
        </>
      }
     </>
  );
}
