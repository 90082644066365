import { Center, Flex, Text } from "@chakra-ui/react";

export default function NotFoundPage() {
    return (
        <>
            <Flex minH={'70vh'} p={8} flex={1} align={'center'} justify={'center'}>
                <Text color={'brand.green.500'}>Oops, nothing exists under this root.  Please climb back up the tree and try again.</Text>
            </Flex>
        </>
    )
}