import React, { useEffect, useState } from 'react';
import {
    Box,
    Center,
    Heading,
    Input,
    InputGroup,
    InputLeftElement,
    useMediaQuery,
    VStack,
    Text, Collapse, Wrap, HStack, useTheme
} from '@chakra-ui/react';
import {Search2Icon} from '@chakra-ui/icons';
import BlogFilter from "./BlogFilter";
import {FaCaretRight} from "react-icons/fa";
import {useGlobalState} from "../../GlobalState";

const changePlaceholderText = (param: number) => {
    switch (param) {
        case 0:
            return 'blog posts';
        case 1:
            return 'images';
        case 2:
            return 'files';
        case 3:
            return 'categories';
        case 4:
            return 'tags';
        default:
            break;
    }
};

export default function BlogHeader({ activeTab, filterRemovalHandler, updateFilters }: any) {
    const [isLessThan480px] = useMediaQuery('(max-width: 480px)');
    let listener: any = null;
    const [scrollState, setScrollState] = useState(false);
    const [filtersExpanded, setFiltersExpanded] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState("");
    const { state, dispatch } = useGlobalState();
    const theme = useTheme();

    const countFilters = (filters: Map<string, Map<string, string>>) => {
        let totalFilters = 0;
        filters.forEach((value, key) => {
            totalFilters += value.size;
        })
        return totalFilters;
    }

    const filterRemovalStateHandler = (filterType: string, filterKey: string) => {
        filterRemovalHandler(filterType, filterKey);
        if (countFilters(state.filters) === 0 && filtersExpanded) {
            setFiltersExpanded(false);
        }
    }

    const [filterCount, setFilterCount] = useState(countFilters(state.filters));

    useEffect(() => {
        setFilterCount(countFilters(state.filters));
    }, [state.filters])
  
    useEffect(() => {
      listener = document.addEventListener("scroll", e => {
        if (document.scrollingElement !== null) {
          var scrolled = document.scrollingElement.scrollTop;
          setScrollState((scrolled >= 120));
        }
      });
      return () => {
        document.removeEventListener("scroll", listener)
      }
    }, [scrollState])

    const updateFilterAreaExpansion = () => {
        if (filterCount > 0) {
            setFiltersExpanded(!filtersExpanded);
        }
    }

    const clearFilters = () => {
        dispatch({ type: 'CLEAR_FILTERS', payload: null});
        setFiltersExpanded(false);
    }

    const handleSearchSubmit = (e: any) => {
        e.preventDefault();
        updateFilters("BlogContent", {key: searchInputValue, value: searchInputValue});
        setSearchInputValue("");
    }

    return (
        <Box py={10} px={[10, 15, 15]} bg={"brand.green.500"}
            pos={'sticky'}
            zIndex={9998}
            top={50}>
            <Center>
                <VStack spacing={6}>
                    {!isLessThan480px && <Heading color={'brand.green.50'} fontFamily={'sans-serif'}>
                        What do you want to {activeTab === 0 ? 'read' : 'explore'}?
                    </Heading>}
                    <Box w={"100%"}>
                        <form onSubmit={handleSearchSubmit}>
                            <InputGroup>
                                <InputLeftElement pointerEvents={'none'}>
                                    <Search2Icon />
                                </InputLeftElement>
                                <Input
                                    type={'text'}
                                    name={'search'}
                                    placeholder={`Search ${changePlaceholderText(
                                        activeTab
                                    )}...`}
                                    value={searchInputValue}
                                    onChange={(e: any) => setSearchInputValue(e.target.value)}
                                    bg={'brand.green.50'}
                                />
                            </InputGroup>
                        </form>
                    </Box>
                    <Box w={"100%"}>
                        <HStack onClick={updateFilterAreaExpansion}>
                            {filterCount > 0 &&
                                <FaCaretRight
                                    aria-label="rotate arrow"
                                    style={{
                                        transform: filtersExpanded ? "rotate(90deg)" : "rotate(0)",
                                        transition: "transform 0.3s ease",
                                        cursor: "pointer",
                                        color: theme.colors.brand.green[50]
                                    }}
                                />}
                            <Text fontFamily={'sans-serif'} color={'brand.green.50'}>{filterCount > 0 ? (filtersExpanded ? "Hide " : "Show ") : ""}{filterCount} applied filters</Text>
                        </HStack>
                        {
                            <Collapse in={filtersExpanded}>
                                <Wrap alignSelf={'flex-start'} paddingTop={5} >
                                    {(Array.from(state.filters.entries()) as [string, Map<string, string>][]).map((entry: [string, Map<string, string>]) => (
                                        Array.from(entry[1].entries()).map(subentry => (
                                            <React.Fragment key={subentry[0]}>
                                                <BlogFilter filterValue={subentry[1]} filterKey={subentry[0]} filterType={entry[0]} filterRemovalHandler={filterRemovalStateHandler}/>
                                            </React.Fragment>
                                        ))
                                    ))}
                                    {filterCount > 0 && <Center><Text marginLeft={5} color={'brand.green.50'} onClick={clearFilters}>Clear All Filters</Text></Center>}
                                </Wrap>
                            </Collapse>
                        }
                    </Box>
                </VStack>
            </Center>
        </Box>
    );
};
