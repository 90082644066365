import {
    Box,
    Image,
    Heading,
    HStack,
    Text,
    Wrap,
} from '@chakra-ui/react';
import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import BlogTag from './BlogTag';

function convertRelativeTime(date: any) {
    return moment(date).fromNow();
};

interface BlogAuthorProps {
  date: string;
  author: any;
}

function createDateFromString(dateString: string): Date {
  const d = Date.parse(dateString);
  return d ? new Date(d) : new Date("");
}

export const BlogAuthor: React.FC<BlogAuthorProps> = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src={props.author?.image?.url}
        alt={`Avatar of ${props.author?.name}`}
      />
      <Text fontWeight="medium">{props.author?.name}</Text>
      <Text>—</Text>
      <Text>{createDateFromString(props.date).toDateString()}</Text>
    </HStack>
  );
};

export default function BlogPostCard(props: any) {
  const post = props.post;
  return (
    <Box
    marginTop={{ base: '1', sm: '5' }}
    display="flex"
    flexDirection={{ base: 'column', sm: 'row' }}
    justifyContent="space-between">
      <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          alignItems="center">
          <Box
              width={{ base: '100%', sm: '85%' }}
              zIndex="2"
              marginLeft={{ base: '0', sm: '5%' }}
              marginTop="5%">
              <Link to={`/blog/${post.slug}`}>
                <Image
                    borderRadius="lg"
                    src={post.coverImage ? post.coverImage.url :
                    'https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=800&q=80'
                    }
                    alt="some good alt text"
                    objectFit="contain"
                />
              </Link>
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
              <Box
                  bgGradient={'radial(orange.600 1px, transparent 1px)'}
                  backgroundSize="20px 20px"
                  opacity="0.4"
                  height="100%" />
          </Box>
      </Box>
      <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}>
          <Heading marginTop="1" color={'brand.green.500'} fontFamily={'sans-serif'}>
            <Link to={`/blog/${post.slug}`} >
              {post.title}
            </Link>
          </Heading>
          <Wrap alignSelf={'flex-start'} mt={3} mb={3} >
            {post.tags?.map((tag: any) => (
              <React.Fragment key={tag.name}>
                <BlogTag editTag={false} updateFilters={props.updateFilters} size={'md'} variant={"solid"} name={tag.name} />
              </React.Fragment>
            ))}
          </Wrap>
          <BlogAuthor author={post.author} date={post.createdAt} />
      </Box>
    </Box>
  )
}
