import { useAuth0 } from '@auth0/auth0-react';
import {
    Stack,
    Flex,
    Button,
    Text,
    useBreakpointValue,
    Heading,
    Image,
    useToast
  } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import bgImg from './bgImg.png';

export default function Landing() {
    return (
        <Flex
        w={'full'}
        minH={'80vh'} 
        bgGradient={'linear(to-b, brand.green.50, brand.lightgreen.500)'}>
            <Stack maxH={'80vh'} direction={{ base: 'column', md: 'row' }}>
                <Flex p={8} flex={1} align={'center'} justify={'center'}>
                    <Stack spacing={6} w={'full'} maxW={'lg'}>
                        <Heading fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}>
                            <Text
                            as={'span'}
                            position={'relative'}
                            _after={{
                                content: "''",
                                width: 'full',
                                height: useBreakpointValue({ base: '20%', md: '30%' }),
                                position: 'absolute',
                                bottom: 1,
                                left: 0,
                                bg: 'brand.green.500',
                                zIndex: -1,
                            }}
                            fontFamily={'sans-serif'}>
                            Exploring Our Branches
                            </Text>
                            <br />{' '}
                            <Text color={'brand.green.300'} as={'span'} fontFamily={'Alex Brush, cursive'}>
                                SassySty's Genealogy Blog
                            </Text>{' '}
                        </Heading>
                        <Text fontFamily={'sans-serif'} fontSize={{ base: 'md', lg: 'lg' }} color={'brand.green.200'}>
                            This is a blog and journal for our family: past, present, and the ones we meet along the way.
                        </Text>
                        <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
                            <Button
                            rounded={'full'}
                            bg={'brand.green.500'}
                            color={'brand.green.50'}
                            _hover={{
                                bg: 'brand.green.900',
                            }}>
                                <Link to={'/blog'}><Text >Visit the Blog</Text></Link>
                            </Button>
                        </Stack>
                    </Stack>
                </Flex>
                <Flex flex={1}>
                    <Image
                        alt={'Landing Page Tree'}
                        objectFit={'cover'}
                        src={bgImg}/>
                </Flex>
            </Stack>
        </Flex>
    )
}